@import '../../constants/constants.css';
.z-0 {
    z-index: 0 !important;
}
.h-full .ReactFlagsSelect-module_selectOptions__3LNBJ {
    max-height: max-content !important;
}

.bg-orange {
    background: #ff8f6b;
    border-left: 1px;
    border-right: 1px;
    border-radius: 50px;
    padding: 0.3rem 1rem;
    margin: auto 1rem;
    white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 765px) {
    .account-block {
        font-family: var(--default-font-family);
        padding: 30px 12px;
    }
}
