.ar-one-free-month {
    position: absolute;
    top: 0;
    right: 0;
    // max-width: 30%;
}

.en-one-free-month {
    position: absolute;
    top: 0;
    left: 0;
    // max-width: 30%;
}

.prices {
    // font-weight: 500 !important;
    font-size: 1.2vw;
}

.opacity-40 {
    opacity: 0.4;
}

.full-height-page-small-nav {
    min-height: calc(100vh - (60px + 56px));
}

.select:hover {
    opacity: 0.6;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.blue-border {
    border: 2px solid #0000ff;
    border-top: none;
}

.border-blue-top {
    border-top: 3px solid #0000ff;
}

.font-size-screen-size {
    font-size: 0.92vw !important;
    width: auto !important;
    letter-spacing: 0.02rem;
    span {
        font-weight: 300 !important;
    }
    .soon {
        font-size: 0.68vw !important;
        color: #22d180;
    }
}

// .select {
//     div.font-size-screen-size {
//         font-size: 0.8vw !important;
//         width: auto !important;
//         letter-spacing: 0.05rem;
//         opacity: 0.8;
//     }
// }

.select {
    padding-top: 3px;
    font-size: 0.8vw !important;
}

.most-popular {
    font-size: 17.5px;
    font-weight: 700;
    line-height: 1.5rem;
}

.bg-ligh-green {
    background: #22d180 !important;
}

.text-w-opacity {
    opacity: 0.3;
}

.w-30 {
    width: 30%;
}

.w-92 {
    width: 92%;
}

.old-price-yr:before {
    position: absolute;
    content: '';
    left: 0;
    top: 48%;
    bottom: 10%;
    right: 0;
    border-top: 1.5px solid red;
    border-color: inherit;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.current-plan {
    background: #e4e8f1 !important;
    color: #66727f !important;
}

@media only screen and (min-width: 1256px) and (max-width: 1387px) {
    .prices {
        font-size: 1vw;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1255px) {
    .prem-month,
    .prem-year,
    .bus-month,
    .bus-year {
        max-width: 100% !important;
        justify-content: center !important;
        flex: 1;
    }

    .or-text {
        width: 100% !important;
    }

    .select {
        font-size: 1vw !important;
    }
}

@media only screen and (min-width: 765px) and (max-width: 991px) {
    .mt-mobile-0 {
        margin-top: 0 !important;
    }

    .font-size-screen-size {
        font-size: 2vw !important;
        .soon {
            font-size: 1.6vw !important;
        }
    }

    .mt-mobile-3 {
        margin: 1.5rem 0 0;
    }

    .select {
        div.font-size-screen-size {
            font-size: 1.2vw !important;
        }
    }

    .prices {
        font-size: 2.3vw;
    }

    .w-screen-size {
        max-width: 70%;
        color: #000;
    }

    .select {
        font-size: 1.8vw !important;
    }
}

@media (max-width: 764px) {
    .mt-mobile-0 {
        margin-top: 0 !important;
    }

    .font-size-screen-size {
        font-size: 3.2vw !important;
        .soon {
            font-size: 2.5vw !important;
        }
    }

    .mt-mobile-3 {
        margin: 1.5rem 0 0;
    }

    .select {
        div.font-size-screen-size {
            font-size: 3vw !important;
        }
    }

    .prices {
        font-size: 3.8vw;
    }

    .select {
        font-size: 3.4vw !important;
    }

    .w-screen-size {
        max-width: 80%;
    }
}
