.copy-all-icon {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    html[dir='rtl'] & {
        margin-right: unset;
        margin-left: 5px;
    }
}

.hover-pointer {
    &:hover {
        cursor: pointer;
        user-select: none;
    }
}

.text-orange {
    color: #ff8f6b;
}
