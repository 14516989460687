.consol-banner-en {
    background: url('./banner-en.png') no-repeat center;
    background-size: cover;
    background-position: center;
    height: 50vw;
    position: relative;
    text-align: start;
    margin-bottom: 3rem;
    span.consolidate {
        position: absolute;
        left: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000ff;
    }

    span.service-text {
        position: absolute;
        left: 10%;
        top: 250px;
        font-size: 2vw;
        color: #000;
    }

    a.signup-btn {
        position: absolute;
        left: 10%;
        top: 400px;
    }
}

.consol-banner-ar {
    background: url('./banner-ar.png') no-repeat center;
    background-size: cover;
    background-position: center;
    height: 50vw;
    position: relative;
    text-align: start;
    margin-bottom: 3rem;
    span.consolidate {
        position: absolute;
        right: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000ff;
    }

    span.service-text {
        position: absolute;
        right: 10%;
        top: 250px;
        font-size: 2vw;
        color: #000;
    }

    a.signup-btn {
        position: absolute;
        right: 10%;
        top: 400px;
    }
}

.w-85 {
    width: 90% !important;
}

//max-width: 768px
@media only screen and (max-width: 768px) {
    .consol-banner-en {
        background: url('./banner-en-mobile.png') no-repeat center;
        background-size: cover;
        height: 100vh;
        position: center;
        span.consolidate {
            position: static;
            max-width: 73vw;
            font-size: 9vw;
            padding-top: 2rem;
            margin-left: 3rem;
            color: #0000ff;
        }

        span.service-text {
            margin: 2rem 0 2rem 3rem;
            position: static;
            display: block;
            font-size: 6vw;
        }
        a.signup-btn {
            position: static;
            margin-left: 3rem;
        }
    }
    .consol-banner-ar {
        background: url('./banner-ar-mobile.png') no-repeat center;
        background-size: cover;
        height: 100vh;
        position: static;
        span.consolidate {
            position: static;
            max-width: 80vw;
            padding-top: 2rem;
            margin-right: 3rem;
            font-size: 5vh;
            margin-bottom: 1rem;
        }

        span.service-text {
            position: static;
            margin: 2rem 3rem 2rem 0;
            font-size: 3.5vh;
            strong {
                display: inline-block;
                padding: 0 3rem 2rem 0;
            }
        }

        a.signup-btn {
            position: static;
            display: inline-block;
            margin-right: 3rem;
        }
    }
}
