:root {
    /* default font family  */
    --default-font-family: 'Kanit';
    --default-ar-font-family: 'Cairo';

    /* font weight */
    --font-weight-600: 600;
    --font-weight-500: 500;
    --font-weight-400: 400;

    /* font size */
    --default-font-size-16: 16px;
    --font-size-main-title-44: 44px;
    --font-size-main-title-mobile-31: 31px;
    --font-size-section-button-18: 18px;
    --font-size-section-title-34: 34px;
    --font-size-card-title-22: 22px;
    --font-size-32: 32px;
    --font-size-26: 26px;
    --font-size-14: 14px;
    --font-size-12: 12px;

    /* default icon width for the webpage */
    --shopini-icon-width: 134px;

    /* navigation container box shadow */
    --navigation-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);

    /* default navigation buttons style */
    --navigation-button-width: 107px;
    --navigation-button-height: 38px;
    --navigation-button-margin-left: 17.5px;

    /* default background colors */
    --white-background-color: #ffffff;
    --grey-background-section-color: #f4f6f9;

    /* default coloring */
    --default-font-color: #243548;
    --green: #22d180;
    --grey: #8798a9;
    --text-grey: #66727f;
    --green-on-hover: #26eb8f;
    --button-text-blue: #0000ff;
    --button-text-blue-400: #445be3;
    --step-text-color: #ff8f6b;

    /* default bordering for buttons */
    --border-none: none;
    --default-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    --default-border-radius: 6px;

    /* default line heights */
    --line-height-navigation-button-24: 23.92px;
    --line-height-main-title-53: 52.8px;
    --line-height-main-title-mobile-37: 37.2px;
    --line-height-main-description-29: 28.8px;
    --line-height-section-title-51: 50.83px;
    --line-height-button-27: 26.91px;
    --line-height-card-title-33: 32.89px;
    --line-height-description-26: 25.6px;
    --line-height-48: 47.84px;
    --line-height-39: 38.87px;

    --card-img-width: 283.09px;
}
