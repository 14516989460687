/*//////////////////  23-7-2020-Tahir ///////////////////////////*/

@media (max-width: 378px) {
    #step-1 > form > .widget__title:after {
        content: '';
        width: 230px;
        height: 1.3px;
        background: #00eb8c;
        display: block;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 0;
    }

    .ml2 {
        margin-left: 0.5rem !important;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .package-row {
        padding-left: 0px !important;
        margin-left: -30px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        /* text-align: left !important;
        font-size: 20px !important;
        line-height: 40px !important;
        font-weight: bold !important;
        padding-left: 0px !important; */
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
        /* padding: 10px 0px 10px 0px !important; */
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .msp-btm-p-last {
        padding-top: 9px;
        padding-left: 0px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
        margin-left: -13px !important;
    }

    .btn_type4 {
        padding: 2px 0px 2px 0px !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 15px 0px 70px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 70px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }
}

@media (max-width: 767px) {
    /*For Responsive   By Arshad*/

    .pay-text {
        display: inline;
        padding-right: 35px;
    }

    .paddingtop2 {
        padding-top: 2px;
    }

    #terms_condition {
        margin-top: 20px;
    }

    .intro__title_slider {
        bottom: 25px;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .genral_logo img {
        width: 100%;
        height: 146px;
    }

    .genral_logo_mb_50 img {
        width: 100%;
        height: 146px;
    }

    .spng_logo img {
        width: 100%;
        height: 146px;
    }

    .orprt_logo {
        text-align: center;
    }

    .orprt_logo img {
        width: 100%;
        height: 146px;
        padding-bottom: 0.95rem;
    }

    .payment-option-logo {
        text-align: center;
    }

    .payment-option-logo img {
        width: 40% !important;
        padding-bottom: 0.95rem;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }

    .cookiealert {
        padding: 0 30px;
    }

    .slider-img {
        width: 100%;
    }

    .reg_from_lft {
        padding: 0px 43px 0 50px;
    }

    #ExchangeAmout {
        font-size: 18px;
        line-height: 20px;
    }

    #ExchangeAmoutDiv > div {
        font-size: 18px;
        line-height: 20px;
    }

    .Shippingfees {
        font-size: 18px;
        line-height: 20px;
    }

    .ShippingfeesDiv > div {
        font-size: 18px;
        line-height: 20px;
    }

    #ExchangeAmoutErrorDiv > p {
        font-size: 18px;
        line-height: 20px;
    }
    .empty-parcel-div {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .padding-left-right-15 {
        padding-left: 7px;
        padding-right: 0px;
    }

    .empty-padding-main-block {
        padding: 0 12px !important;
    }

    .margin-top-negative51 {
        margin-top: 0px;
    }

    .response-bg-div {
        background-color: #ffffff;
    }

    .content-mb {
        padding-bottom: 0px !important;
    }

    .margin-top-negative100 {
        margin-top: 0px;
    }

    .margin-top-negative85 {
        margin-top: 0px;
    }

    .font-size-mb-18 {
        font-size: 20px !important;
    }

    .payment-option-save-card-height {
        margin: 0;
    }

    .padding-left-step5-120 {
        padding-left: 16px;
    }

    .padding-left-step5-col2-80 {
        padding-left: 16px;
    }
}

@media (max-width: 1200px) {
    .banner02 {
        padding-top: 0px;
    }

    .banner04 {
        padding-top: 0px;
    }

    .banner05 {
        padding-top: 0px;
    }

    .banner06 {
        padding-top: 0px;
    }

    .banner07 {
        padding-top: 0px;
    }
}

@media (max-width: 770px) {
    .Banner-img-ul {
        padding-top: 0px;
        padding-left: unset;
    }

    .Banner-img-ol {
        margin-top: 10px;
        padding-left: 13px;
    }

    .banner01 {
        width: 60%;
    }

    .banner02 img {
        width: 100%;
    }
}

/* ////////END////////// */

.rowtpb-40 {
    padding-top: 40px;
    padding-bottom: 15px;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.55;
    color: #c3cbd4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #0000ff;
}

@media (min-width: 1200px) {
    .btn_type4 {
        color: #fff;
        background: #0000ff;
        text-transform: uppercase;
        padding: 12px;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        min-width: auto;
    }

    .package-info-item {
        padding: 0px 0px 2px 0px !important;
        position: relative;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 0px 0px 2px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 0px 0px 2px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .msp-btm-p-last {
        padding-top: 0px;
        padding-left: 0px;
    }

    .msp-btm-p-last-member {
        padding-top: 0px;
        padding-left: 0px;
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    /* .dropdown-menu.show {
        display: block;
        top: -15px !important;
    } */
    .membership-down-grade {
        background: #000;
        color: #000;
        text-transform: uppercase;
        padding: 12px;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        min-width: auto;
        cursor: not-allowed !important;
        display: none;
    }
}

#accordionExample {
    margin: 5px;
}

.widget.widget_about .widget__inner {
    padding: 40px 40px;
}

.widget-inner-full {
    padding: 40px 40px;
}

@media screen {
    .select2 {
        width: 100% !important;
    }
}

.pt40 {
    padding-top: 40px;
}

.pt20 {
    padding-top: 20px !important;
}

.pb-20 {
    padding-bottom: 20px;
}

.mtb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mrgb15 {
    margin-bottom: 15px;
    padding-top: 0px !important;
}

.pt0 {
    margin-bottom: 15px;
    margin-top: 0px !important;
}

.div-block-right {
    float: right;
}

.middle-nav > ul > li > a {
    display: block;
    padding: 10px 40px 13px;
    text-transform: uppercase;
    color: #999999 !important;
    font-size: 17px;
    line-height: 18px;
}

.middle-nav > ul > li > div > a {
    font-size: 16px;
    line-height: 16px;
    display: block;
    padding: 8px 20px;
    color: #999;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
}

.middle-nav > ul > li > div > a:hover {
    background: #0000ff;
    color: #fff;
}

.middle-nav > ul > li > .dropdown-menu {
    border: none !important;
}

.middle-nav > ul > li > .dropdown-menu .dropdown-divider {
    margin: 0px !important;
}

#postal,
.mt5 {
    margin-top: 0px;
}

.btn_cancel {
    background: #f7f7f7;
    color: #000;
    text-transform: uppercase;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
}

.btn_back {
    background: #0000ff;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
}

.btn_back:hover {
    background: #00eb8c !important;
    color: #fff;
}

.image-checkbox-nopointer {
    cursor: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 0px solid transparent;
    margin-bottom: 0;
    outline: 0;
    min-width: 130px;
}

.btn-primary {
    color: #fff;
    background-color: #0a20e1;
    border-color: #fff;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
}

.btn-google {
    color: #212529;
    background-color: #ffffff;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    border-color: #00eb8c;
    border: solid 1px;
    text-transform: uppercase;
}

.btn-google:hover {
    background-color: #00eb8c;
    color: #fff;
}

.m-auto {
    margin: auto !important;
}

.acnt_input_w85 {
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 1px solid #d7d7d7;
    width: 95%;
}

.widget.widget_packages .btn-p {
    padding: 0px 10px;
    min-width: 0;
    border: none;
}

.input-group-append-left {
    margin-right: -12px;
}

.incoming-order-total {
    font-weight: 300;
    font-size: 20px;
    float: right;
    margin-right: 15px;
}

.btn_type_rm {
    color: #fff;
    background: #0000ff;
    text-transform: uppercase;
    padding: 0px 10px !important;
    font-weight: bold;
    min-width: auto;
}

.btn_type_plus {
    color: #fff;
    background: #00eb8c;
    text-transform: uppercase;
    padding: 0px 10px !important;
    font-weight: bold;
    min-width: auto;
}

.btn_type_plus:hover {
    color: #fff;
    background: #0000ff;
}

.mr2 {
    margin-right: 0.75rem;
}

/*
[type=checkbox]+label:before, [type=checkbox]:not(.filled-in)+label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

[type=checkbox]+label {
    padding-left: 26px;
    height: 25px;
    line-height: 21px;
    font-weight: normal;
}

[type=checkbox]:checked, [type=checkbox]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 3;
} */

.content-login {
    background: #eef1f5;
    padding-top: 0px;
    padding-bottom: 25px;
}

.ml15 {
    margin-left: 15px;
}

.swal2-popup #swal2-content {
    text-align: left !important;
}

.btn_type5 {
    background: #0000ff;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
}

.up-service-min-height {
    min-height: 120px;
}

.services_card input {
    width: 120px;
}

@media (max-width: 760px) {
    .header .logo img {
        /*  max-width: 50%;*/
        display: block;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
        padding-bottom: 0.95rem;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
        padding-bottom: 0.95rem;
    }

    .spng_logo_50 img {
        width: 50% !important;
        padding-bottom: 0.95rem;
    }

    .usr-nav {
        top: -94px;
        left: 10px;
        padding-top: 5px;
    }

    .mobile-nav-btn {
        top: -47px;
    }

    .slider-heading {
        font-size: 30px;
        margin: 0px 15px 0 15px;
    }

    .header_slide_text {
        margin: 0px 15px 0 15px;
    }

    .header .logo {
        margin-bottom: 17px;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .left_heading_title img {
        max-width: 33% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }

    .reg_form_heading {
        margin-top: 40px;
    }

    .reg_rgtimg_txt {
        float: left;
        margin: 10px 0px 0 -30px;
    }

    .img-responsive {
        max-width: 33%;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    .padding-vertical-20 {
        padding: 10px 0;
    }
}

@media (max-width: 768px) {
    .up-service-min-height {
        min-height: 0px;
    }

    .custom-control {
        padding-left: 1.5rem !important;
        float: left !important;
    }

    .intro__item2 p {
        padding-left: 40px !important;
        padding-right: 0px !important;
    }

    .intro__title_slider2 span {
        color: white !important;
    }

    .intro__item-inner2 {
        margin-top: 0px !important;
    }

    .intro__title_slider2-service p {
        display: none;
    }
}

@media (min-width: 740px) {
    .custom-modal-width-646 {
        max-width: 646px !important;
    }

    .custom-modal-width-546 {
        max-width: 546px !important;
    }

    .custom-modal-width-750 {
        max-width: 750px !important;
    }

    .custom-modal-width-1346 {
        max-width: 1346px !important;
    }

    .modal-margin-top-200 {
        margin-top: 200px;
    }

    .padding40 {
        padding: 40px;
    }

    .padding20 {
        padding: 20px;
    }

    .margin-vertical-22 {
        margin: 22px 0;
    }

    .padding-vertical-15 {
        padding: 15px 0;
    }

    .width-90-percent {
        max-width: 90%;
    }
    .padding-vertical-20 {
        padding: 20px 0;
    }
}

@media (min-width: 765px) and (max-width: 992px) {
    .margin-right-9-rm {
        margin-right: 1.5rem !important;
    }

    .mrtopcls {
        margin-top: 50px;
    }

    .usr-nav ul {
        width: 57%;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 50% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .heading-block-hsw {
        font-size: 20px;
        color: #0000ff;
        font-weight: 600;
        display: contents;
        float: left;
        margin-left: 25px;
    }

    .heading-block-hsw img {
        margin-top: 5px;
        margin-left: 50px;
        margin-bottom: 25px;
        width: 120px !important;
    }

    .textuppercase-why-choose {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 18px;
        font-weight: bold;
        margin-top: -10px;
    }

    .textuppercase {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 24px;
        font-weight: bold;
        margin-top: -15px;
    }

    .subtitle_text {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -1px;
    }

    .subtitle_text2 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -1px;
    }

    .subtitle_text3 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -1px;
    }

    .subtitle_text4 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -1px;
    }

    .p_hw {
        float: left;
        margin: 0px 25px;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }
}

@media (min-width: 750px) and (max-width: 1000px) {
    .search-form .form-control {
        font-size: 9px;
        padding: 8px 5px;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 50% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .paynow_btn {
        width: 140px;
    }

    .btn_unpayment {
        width: 160px;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 50% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .ml2 {
        margin-left: 0.5rem !important;
    }
}

@media (min-width: 1760px) and (max-width: 2600px) {
    .how-it-work-title {
        color: #0000ff;
        font-size: 15px !important;
        line-height: 5px !important;
        font-weight: bold;
        padding-top: 10px !important;
        margin: 1 0 0 2%;
        position: relative;
        z-index: 1;
    }

    .howitworks {
        position: absolute;
        top: 60px !important;
        left: 160px !important;
        color: #0000ff;
        font-size: 40px !important;
        line-height: 30px !important;
        font-weight: bold;
    }
}

@media (min-width: 901px) and (max-width: 990px) {
    .how-it-work-title {
        color: #0000ff;
        font-size: 30px;
        line-height: 10px;
        font-weight: bold;
        padding-top: 60px !important;
        margin: 2 0 0 7%;
        position: relative;
        z-index: 1;
    }

    .howitworks {
        position: absolute;
        top: 60px;
        left: 60px !important;
        color: #0000ff;
        font-size: 20px;
        line-height: 10px;
        font-weight: bold;
    }

    .reg_rgtimg_txt {
        float: left;
        margin: 10px 0 0 -30px;
    }
}

@media (min-width: 320px) and (max-width: 420px) {
    .how-it-work-title {
        color: #0000ff;
        font-size: 15px !important;
        line-height: 5px !important;
        font-weight: bold;
        padding-top: 10px !important;
        margin: 1 0 0 2%;
        position: relative;
        z-index: 1;
    }

    .howitworks {
        position: absolute;
        top: 60px;
        left: 60px !important;
        color: #0000ff;
        font-size: 20px !important;
        line-height: 20px !important;
        font-weight: bold;
    }

    .our-service-header-txt {
        margin: 20px 0 0 0;
        text-align: center;
    }

    .img-responsive {
        max-width: 33%;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .img-fluid {
        max-width: 33%;
    }

    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    /* .benefits div {
        display: none;
    } */
    .package-row {
        padding-left: 0px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 16px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        /* text-align: left !important;
        font-size: 20px !important;
        line-height: 40px !important;
        font-weight: bold !important;
        padding-left: 0px !important; */
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
        /* padding: 10px 0px 10px 0px !important; */
    }

    .btn_type4 {
        padding: 2px 1px 2px 1px !important;
        font-weight: bold !important;
        font-size: 12px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 12px 0px 30px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 12px 0px 30px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .why-desc {
        float: left;
        width: auto !important;
        text-align: left;
        margin: 5px 0 30px 0;
    }
}

@media (max-width: 800px) {
    .res_cls {
        float: none;
        margin: 10px 0 0 0;
    }

    .text_mrg {
        left: 5%;
        right: 5%;
    }
}

@media (min-width: 400px) {
    .img-shipping-rate-icon {
        max-width: 20%;
    }
}

@media (max-width: 760px) {
    .widget-inner2 .service_desc p {
        display: block;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: unset;
        text-overflow: unset;
        margin-bottom: unset;
    }

    .read-more {
        display: none;
    }
}

@media (min-width: 768px) {
    .img-shipping-rate-icon {
        max-width: 35%;
    }
}

@media (min-width: 992px) {
    .img-shipping-rate-icon {
        max-width: 45%;
    }

    .navbar-nav {
        width: 816px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .img-shipping-rate-icon {
        max-width: 45%;
    }
}

@media (max-width: 1200px) {
    .our-benefits__sub-title {
        margin: 30px 0 0 10px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .our-service-header-txt {
        margin: 125px 0 0 0;
    }
}

@media (max-width: 1192px) {
    .card-banner {
        float: left !important;
        margin-top: 10px;
    }
}

@media (max-width: 500px) {
    .intro .slick-next,
    .intro .slick-prev {
        display: none;
    }

    .our-service-header-txt {
        margin: 20px 0 0 0;
    }

    .our-service-green-img {
        height: 250px;
    }

    .text_mrg {
        left: 1%;
        right: 1px;
    }

    .img-responsive {
        max-width: 33%;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .img-fluid {
        max-width: 33%;
    }

    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    /* .benefits div {
        display: none;
    } */
    .package-row {
        padding-left: 0px !important;
        margin-left: -30px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        /* text-align: left !important;
        font-size: 20px !important;
        line-height: 40px !important;
        font-weight: bold !important;
        padding-left: 0px !important; */
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
        /* padding: 10px 0px 10px 0px !important; */
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .msp-btm-p-last {
        padding-top: 9px;
        padding-left: 0px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
        margin-left: -13px !important;
    }

    .btn_type4 {
        padding: 2px 0px 2px 0px !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 15px 0px 70px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 70px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }
}

@media screen and (max-width: 1199px) {
    .td-vertical-top td {
        vertical-align: top !important;
    }

    .padding-left-140 {
        padding-left: 16px;
    }

    .mb-margin16 {
        margin: 0px 16px;
    }

    .mb-margin32 {
        margin: 0px 32px;
    }

    .mb-height {
        height: 32px;
    }

    .padding-top32 {
        padding-top: 16px;
    }

    .mb-margin-bottom-0 {
        margin-bottom: 0rem;
    }
    .mb-padding-top-16 {
        padding-top: 16px;
    }

    .padding-left100 {
        padding: 16px 24px;
    }

    .margin-right-bottom {
        margin: 0px 0px 16px 0px;
    }

    .line-height-33 {
        line-height: 33px !important;
    }

    .line-height-30 {
        line-height: 30px !important;
    }

    .padding-bottom-16 {
        padding-bottom: 16px;
    }

    .order-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .mb-text-center {
        text-align: center;
    }

    .mfs-26 {
        font-size: 26px !important;
        line-height: 39px !important;
    }

    .mfs-22 {
        font-size: 22px !important;
        line-height: 33px !important;
    }

    .mfs-20 {
        font-size: 20px !important;
    }

    .padding-48 {
        padding: 0px 16px 24px 16px;
    }

    .padding-20 {
        padding: 12px 16px 0px 16px;
    }

    .no-padding {
        padding: 0px;
    }

    .container-minheight {
        height: auto;
        overflow: auto;
    }

    .widget {
        padding-bottom: 24px;
    }

    .widget-inner-reset-pass {
        max-width: 100%;
    }

    .widget .widget-inner-50 {
        padding: 16px 16px;
    }

    .card-body-padding-30 {
        padding: 16px 16px;
    }

    .sec .img-wrap,
    .sec h2,
    .sec h3,
    .sec .btn-wrap,
    .sec p {
        text-align: center;
    }

    .right_heading_title {
        padding-top: 0px !important;
        float: none !important;
    }

    .right_heading_subtitle {
        float: none !important;
    }

    .right_sec_p {
        margin-right: 0px !important;
        padding-right: 20px !important;
        float: none !important;
    }

    .button_right {
        float: none !important;
        margin-right: 0px !important;
    }

    .widget__inner2 {
        margin-bottom: 20px;
        padding-top: 20px !important;
    }

    .widget__inner3 {
        margin-bottom: 20px;
        padding-top: 20px !important;
        padding-left: 20px !important;
    }

    .shipping_simplified_row {
        display: none;
    }

    .left_sec_p {
        padding-right: 0px !important;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .left_sec_p {
        padding-right: 20px !important;
        padding-left: 0px !important;
        float: none !important;
        margin-left: 0px !important;
    }

    .button_left {
        float: none !important;
        margin-left: 0px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 0;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }
}

@media (max-width: 992px) {
    .tracking-item .tracking-date {
        position: absolute;
        left: -10rem;
        width: 7.5rem;
        text-align: right;
    }

    .tracking-item .tracking-date span {
        display: block;
    }

    .tracking-item .tracking-content {
        padding: 0;
        background-color: transparent;
    }

    .form-check-label2 {
        margin-top: 0px;
        margin-left: 30px;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .widget__content_ticket {
        max-width: 544px;
    }

    .widget__inner_fix_height {
        min-height: 250px !important;
    }

    .widget__inner {
        min-height: 250px !important;
    }

    .text_right {
        text-align: right !important;
    }

    .prohibited-materials-image {
        max-width: 15%;
    }

    .mrtopcls {
        margin-top: 15px;
    }
}

@media (max-width: 1370px) {
    .images-container {
        display: block !important;
    }

    .payment_option_button {
        margin-top: 20px;
    }
}

/******************************* bootstrap *********************************/

@media (max-width: 575.98px) {
    .padding-left16 {
        padding-right: 16px;
    }

    .mb-padding-right-left-16 {
        padding: 0 16px;
    }

    .mb-padding-left0 {
        padding-left: 0px;
    }

    .widget-inner-padding-16 {
        padding: 16px !important;
    }

    .service-prohibited-material-block-right-padding {
        padding-right: 0px !important;
    }

    .mb-card-footer {
        padding: 0.25rem 0.15rem;
    }

    .padding-15left {
        padding-left: 15px !important;
    }

    .right-block-img {
        padding: 0 0 0 9px !important;
    }

    .td-bg .table td {
        background-color: #f4f6f9 !important;
        border-color: #f4f6f9 !important;
    }

    #innertble > tbody > tr > td {
        background-color: #fff !important;
    }

    .innertble-margin-b0 {
        margin-bottom: 0 !important;
    }

    .padding-1rm {
        // padding: 0rem!important;
    }

    .img-padding-0 {
        padding: 0px;
    }

    .parcel-table .table td {
        padding: 0.25rem 0rem 0rem 0rem;
        vertical-align: middle;
    }
    /***********************for modal open from right*********/
    .modal.right .modal-body {
        padding: 24px 1px 0px 1px;
    }

    .modal.right.fade .modal-dialog {
        right: -360px;
        -webkit-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -moz-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -o-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
    }

    .modal.right.fade.show .modal-dialog {
        right: 0;
    }

    .right.modal-content {
        border-radius: 0;
        border: none;
    }

    /* ----- for modal open from right ----- */
    .margin-top13 {
        margin-top: 13px;
    }

    .margin-top5 {
        margin-top: 5px;
    }

    .margin-right10 {
        margin-right: 10px;
    }

    .only-mb-hide {
        display: none;
    }

    .modal-body-padding-mb {
        padding: 0px;
    }

    .card-body-mb {
        padding: 0px;
    }

    .responsive-margint16 {
        margin-top: 16px;
    }

    .responsive-mrgnbtm30 {
        margin-bottom: 30px;
    }

    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .contentdisplay {
        display: contents;
    }

    /***************************************dashboard mobile*******************************/
    #mparcels > nav .nav-tabs {
        border-bottom: 0px solid #dee2e6;
    }

    .dashboard-container,
    .container2 {
        width: 100%;
        // padding-right: 1px;
        // padding-left: 1px;
        // margin-right: auto;
        // margin-left: auto;
        padding: 0px;
    }

    .widget__inner {
        background: #fff;
        padding: 24px 0px 0px 0px;
        position: relative;
        width: 100%;
    }

    .table td,
    .table th {
        padding: 0.35rem;
    }

    #mytable > thead > tr > th:nth-child(1) {
        padding: 6px 16px 4px 16px;
    }

    #mytable > tbody > tr > td:nth-child(1) {
        padding: 10px 16px 0px 16px;
    }

    .mb-show {
        display: contents !important;
    }

    .mb-show-block {
        display: block !important;
    }

    .mb-hide {
        display: none !important;
    }

    .pdl13 {
        padding: 0px 3px;
    }

    .padding-nav-27 {
        padding: 0px 3px;
    }

    .padding-nav-59 {
        padding: 0px 3px;
    }

    #mparcels nav > div > a {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        color: #243548;
        width: auto !important;
    }

    #mparcels .nav-tabs .nav-item {
        margin-bottom: -1px;
        margin-left: 0px;
        padding-right: 2px;
        margin-inline: 4px;
        a {
            margin-bottom: -1px;
            margin-left: 0px;
            padding-right: 2px;
            font-size: 15px;
        }
    }

    #mparcels .nav-link {
        display: block;
        padding: 0.35rem 0.125rem 0 0.125rem;
    }

    .mobile_parcel_minheight {
        min-height: auto !important;
        padding: 0px;
    }

    .pdnglrt22 {
        padding: 0px 22px;
    }

    .pdng-lr100 {
        padding: 0px 16px;
    }

    .footer-sticky {
        position: fixed;
        bottom: 4px;
        background: #f4f6f9;
        z-index: 10;
        left: 0;
    }

    .pdngtb16 {
        padding: 20px 0px 30px 0px;
        margin-left: -8px;
    }

    .bg-gry6 {
        background: #f4f6f9 !important;
    }

    .sptopbrdr0 {
        border-top: 0px !important;
    }

    .brdr0tbl {
        border-top: 0px !important;
    }

    #gallery-202 > li:nth-child(1) {
        padding-left: 0px;
    }

    .imgwdth150 {
        width: 120px;
    }

    .dimsn-wght-pdng {
        padding: 2px 16px 2px 30px !important;
    }

    .padtb-15 {
        padding: 15px 10px 0px 0px;
    }

    .pdnglftt30 {
        padding-left: 31px;
    }

    .pdl16 {
        padding: 0px 0px 0px 16px;
    }

    .drawer {
        width: 100% !important;
    }

    .pdngtp20 {
        padding: 0px 10px 0px 33px;
        margin-top: 11px;
    }

    /***************************dashboard mobile ***********************************/
    .padding40 {
        padding: 16px;
    }

    .widget.widget_packages .widget__inner80 {
        padding: 40px 16px;
    }

    .verify-email-height {
        height: auto;
    }

    .padding250,
    .padding200,
    .paddingtop150,
    .paddingtop100 {
        padding: 50px 0px 0px 0px;
    }

    .image-social-btn span {
        position: absolute;
        width: 100%;
        text-transform: uppercase;
        padding: 17px 35px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        left: 18px;
    }

    .check_icon_4 {
        margin-left: 100px;
    }

    .sw-theme-default > ul.step-anchor > li {
        position: relative;
        margin-right: 1px;
        text-transform: uppercase;
        line-height: 19px;
    }

    .text_heading_blue {
        /* text-transform: uppercase; */
        color: #0000ff;
        text-align: justify;
        font-size: 2.25rem;
    }

    .pd50 {
        padding-left: 2px;
    }

    .cal-slider-left {
        float: right;
        position: absolute;
        width: 70%;
        right: 16px;
    }

    .cal-slider-heading {
        position: absolute;
        font-size: 14px;
        margin: 5px 0px 0px 100px;
    }

    .cal-slider-text {
        position: absolute;
        font-size: 8px;
        margin: 30px 20px 15px 70px;
    }

    #loginModal > div > div > div.modal-body > div > form > div.col-md-3.offset-md-3 > button {
        margin: 0 0 0 45px;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 33% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 0;
    }

    .reg_right_img img {
        max-width: 33% !important;
        display: inline-block;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    .img-fluid {
        max-width: 33%;
    }

    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    /* .benefits div {
        display: none;
    } */
    .img-fluid {
        max-width: 33%;
    }

    .package-row {
        padding-left: 0px !important;
        margin-left: -30px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        /* text-align: left !important;
        font-size: 20px !important;
        line-height: 40px !important;
        font-weight: bold !important;
        padding-left: 0px !important; */
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
        /* padding: 10px 0px 10px 0px !important; */
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .msp-btm-p-last {
        padding-top: 9px;
        padding-left: 0px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
        margin-left: -13px !important;
    }

    .btn_type4 {
        padding: 2px 0px 2px 0px !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    #forgetpswModal > div > div > div.modal-body > div > form > div.col-md-3.offset-md-3 > button {
        margin: 0 0 0 45px;
    }

    #myaddress > div > div > div.modal-body > div > div > form > div > div.col-md-12.offset-md-5 > button {
        margin: 0 0 0 45px;
    }

    .rem_text {
        margin: -4px 0px 0px 4px;
    }

    .how-it-work-title {
        color: #0000ff;
        font-size: 15px !important;
        line-height: 5px !important;
        font-weight: bold;
        padding-top: 10px !important;
        margin: 1 0 0 2%;
        position: relative;
        z-index: 1;
    }

    .howitworks {
        position: absolute;
        top: 60px;
        left: 60px !important;
        color: #0000ff;
        font-size: 20px !important;
        line-height: 20px !important;
        font-weight: bold;
    }

    .our-service-header-txt {
        margin: 20px 0 0 0;
        text-align: center;
    }

    .img-responsive {
        max-width: 33%;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .usr-nav a {
        font-size: 15px;
    }

    .img-shipping-rate-icon {
        max-width: 35%;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 15px !important;
        font-weight: bold !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 50% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .ml2 {
        margin-left: 0.5rem !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }

    .reg_rgtimg_txt {
        float: left;
        width: auto;
        margin: 10px 0 0 20px;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    .img-fluid {
        max-width: 33%;
    }

    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    /* .benefits div {
        display: none;
    } */
    .package-row {
        padding-left: 0px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 16px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
    }

    .btn_type4 {
        padding: 2px 1px 2px 1px !important;
        font-weight: bold !important;
        font-size: 12px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 12px 0px 44px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 12px 0px 44px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .mrgtp-12 {
        margin-top: -12px;
    }

    .icon-heading {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .description {
        width: 70% !important;
    }

    .img-row {
        padding-left: 10px !important;
    }

    .why-desc {
        float: left;
        width: auto !important;
        text-align: left;
        margin: 5px 0 30px 0;
    }

    .modal-content {
        width: auto;
        margin: 0px;
        max-height: 100vh;
        min-height: 100%;
        border-radius: 0;
    }

    .banner-heading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .banner-sub-heading {
        font-size: 10px;
        font-weight: 200;
        line-height: 10px;
        margin-bottom: 40px;
    }

    .why_chooseus_txt_center {
        top: 40% !important;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 33% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 33% !important;
        display: inline-block;
    }

    .heading-block-hsw {
        font-size: 20px;
        color: #0000ff;
        font-weight: 600;
        display: contents;
        float: left;
        margin-left: 25px;
    }

    .heading-block-hsw img {
        margin-top: 5px;
        margin-left: 125px;
        margin-bottom: 25px;
        width: 25% !important;
        text-align: center;
    }

    .textuppercase-why-choose {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 18px;
        font-weight: bold;
        margin-top: -10px;
    }

    .textuppercase {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 24px;
        font-weight: bold;
        margin-top: -15px;
    }

    .subtitle_text {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: -95px;
        margin-top: 15px;
    }

    .subtitle_text2 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -4px;
    }

    .subtitle_text3 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -4px;
    }

    .subtitle_text4 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: -4px;
    }

    .p_hw {
        float: left;
        margin: 0px 25px;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .img-fluid {
        max-width: 33%;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    /* .benefits div {
        display: none;
    } */
    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    .package-row {
        padding-left: 0px !important;
        margin-left: -30px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
    }

    .row-margin-n30 {
        margin-top: -10px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .msp-btm-p-last {
        padding-top: 38px;
        padding-left: 0px;
    }

    .msp-btm-p-last-member {
        padding-top: 15px;
        padding-left: 0px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
        margin-left: 2px !important;
    }

    .btn_type4 {
        padding: 2px 1px 2px 1px !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
    }

    .btn-thrd-mrg {
        margin: 0px !important;
    }

    .msp-btm-pl {
        padding: 47px 0px 0px 0px !important;
    }

    .msp-btm-pl-member {
        padding: 0px !important;
    }

    .acnt_input-2 {
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        width: 90%;
        font-size: 9px;
    }

    .acnt_input-3 {
        background-color: #eef1f5 !important;
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        color: #000 !important;
        width: 65% !important;
        margin-top: 30px !important;
        font-size: 9px !important;
    }

    .form-error {
        font-size: 7px;
    }

    .autorities img {
        max-width: 40% !important;
    }

    .why-desc {
        float: left;
        width: auto !important;
        text-align: left;
        margin: 5px 0 30px 0;
    }

    .text-box_secondary3 {
        background: #fff !important;
    }

    .text-box_secondary2 {
        background: #0000ff !important;
    }

    .text-color-white2 {
        color: white !important;
    }

    .text_color_blue2 {
        color: #0000ff !important;
    }

    .text_dv_color {
        color: #212529;
    }

    .no-hidden-fees {
        padding-top: 20px;
    }

    .calculator-hd-img {
        text-align: center;
    }

    .calculator-hd-img img {
        max-width: 50%;
    }

    .how-it-works__item {
        text-align: center;
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .pd-t20 {
        padding-top: 1px;
    }

    .ass_pur_tbl {
        margin-left: -45px !important;
    }

    #accountSetting .col-md-5 .text-uppercase {
        text-align: center;
    }

    .payment_getway_company_container {
        padding-bottom: 20px;
    }

    .payment_option_button {
        text-align: center !important;
    }

    .dashboard-parcel-list {
        width: 20.9rem !important;
        margin-left: 15px;
        margin-bottom: 25px;
    }

    .orprt-mt-3,
    .orprt-my-3 {
        margin-top: 1.25rem;
    }

    .orprt_card_brd {
        border: 1px solid #00eb8c;
        min-height: 175px;
    }

    /* .dropdown-menu.show {
        display: block;
        top: 0px !important;
    } */
    .membership-down-grade {
        background: #000;
        color: #000;
        text-transform: uppercase;
        padding: 2px 1px 2px 1px;
        font-size: 10px;
        line-height: 16px;
        min-width: auto;
        cursor: not-allowed !important;
        display: none;
    }

    .why_chooseus_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }

    .how_it_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }

    .services_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }

    .payment-option-checkbox {
        position: absolute;
        padding: 10px;
        margin-top: -26px;
        right: 0;
        left: 0px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .padding-left16 {
        padding-right: 16px;
    }

    .padding-15left {
        padding-left: 15px !important;
    }

    .right-block-img {
        padding: 0 0 0 9px !important;
    }
    .widget-inner-padding-16 {
        padding: 16px !important;
    }

    .service-prohibited-material-block-right-padding {
        padding-right: 0px !important;
    }

    .mb-card-footer {
        padding: 0.25rem 0.15rem;
    }

    .td-bg .table td {
        background-color: #f4f6f9 !important;
        border-color: #f4f6f9 !important;
    }

    #innertble > tbody > tr > td {
        background-color: #fff !important;
    }

    .img-padding-0 {
        padding: 0px;
    }

    /***********************for modal open from right*********/
    .modal.right .modal-body {
        padding: 24px 22px 22px;
    }

    .modal.right.fade .modal-dialog {
        right: -360px;
        -webkit-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -moz-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -o-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
    }

    .modal.right.fade.show .modal-dialog {
        right: 0;
    }

    .right.modal-content {
        border-radius: 0;
        border: none;
    }

    /* ----- for modal open from right ----- */
    .margin-top5 {
        margin-top: 5px;
    }

    .margin-right10 {
        margin-right: 10px;
    }

    .only-mb-hide {
        display: none;
    }

    .modal-body-padding-mb {
        padding: 0px;
    }

    .card-body-mb {
        padding: 0px;
    }

    .responsive-margint16 {
        margin-top: 16px;
    }

    .responsive-mrgnbtm30 {
        margin-bottom: 30px;
    }

    /***************************************dashboard mobile*******************************/
    #mparcels > nav .nav-tabs {
        border-bottom: 0px solid #dee2e6;
    }

    .dashboard-container,
    .container2 {
        width: 100%;
        // padding-right: 1px;
        // padding-left: 1px;
        // margin-right: auto;
        // margin-left: auto;
        padding: 0px;
    }

    .widget__inner {
        background: #fff;
        padding: 24px 0px 0px 0px;
        position: relative;
        width: 100%;
    }

    .table td,
    .table th {
        padding: 0.35rem;
    }

    #mytable > thead > tr > th:nth-child(1) {
        padding: 6px 16px 4px 16px;
    }

    #mytable > tbody > tr > td:nth-child(1) {
        padding: 10px 16px 0px 16px;
    }

    .mb-show {
        display: contents;
    }

    .mb-hide {
        display: none !important;
    }

    .pdl13 {
        padding: 0px 3px;
    }

    .padding-nav-27 {
        padding: 0px 3px;
    }

    .padding-nav-59 {
        padding: 0px 3px;
    }

    #mparcels nav > div > a {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        color: #243548;
        width: auto !important;
    }

    #mparcels .nav-tabs .nav-item {
        margin-bottom: -1px;
        margin-left: 0px;
        a {
            margin-bottom: -1px;
            margin-left: 0px;
        }
    }

    #mparcels .nav-link {
        display: block;
        padding: 0.25rem 0.95rem;
    }

    .parcel_minheight {
        min-height: auto !important;
    }

    .pdnglrt16 {
        padding: 0px 16px;
    }

    .pdng-lr100 {
        padding: 0px 16px;
    }

    .footer-sticky {
        position: fixed;
        bottom: 4px;
        background: #f4f6f9;
        z-index: 10;
    }

    .pdngtb16 {
        padding: 20px 21px 30px 0px;
        margin-left: -8px;
    }

    .bg-gry6 {
        background: #f4f6f9;
    }

    .sptopbrdr0 {
        border-top: 0px !important;
    }

    .brdr0tbl {
        border-top: 0px !important;
    }

    #gallery-202 > li:nth-child(1) {
        padding-left: 0px;
    }

    .imgwdth150 {
        width: 120px;
    }

    .dimsn-wght-pdng {
        padding: 2px 16px 2px 30px !important;
    }

    .padtb-15 {
        padding: 15px 10px 0px 0px;
    }

    .pdnglftt30 {
        padding-left: 31px;
    }

    .pdl16 {
        padding: 0px 0px 0px 16px;
    }

    .drawer {
        width: 380px !important;
    }

    .pdngtp20 {
        padding: 20px 10px 0px 10px;
    }

    /***************************dashboard mobile ***********************************/
    .padding40 {
        padding: 16px;
    }

    .widget.widget_packages .widget__inner80 {
        padding: 40px 16px;
    }

    .verify-email-height {
        height: auto;
    }

    .padding250,
    .padding200,
    .paddingtop150,
    .paddingtop100 {
        padding: 50px 0px 0px 0px;
    }

    .payment-option-checkbox {
        position: absolute;
        padding: 10px;
        margin-top: -26px;
        right: 0;
        left: 0px;
    }

    .check_icon_4 {
        margin-left: 235px;
    }

    .sw-theme-default > ul.step-anchor > li {
        position: relative;
        margin-right: 1px;
        text-transform: uppercase;
        line-height: 19px;
    }

    .text_heading_blue {
        /* text-transform: uppercase; */
        color: #0000ff;
        text-align: justify;
        font-size: 2.25rem;
    }

    .pd50 {
        padding-left: 2px;
    }

    .cal-slider-heading {
        position: absolute;
        font-size: 14px;
        margin: 5px 0px 0px 100px;
    }

    .cal-slider-text {
        position: absolute;
        font-size: 8px;
        margin: 30px 20px 15px 70px;
    }

    .why_chooseus_txt_center {
        top: 45% !important;
    }

    .our-service-header-txt {
        margin: 60px 0 0 0;
    }

    .img-responsive {
        max-width: 33%;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .img-fluid {
        max-width: 33%;
    }

    .mb-dv div {
        display: none;
    }

    #choose-us {
        display: none;
    }

    /* #g-header-mb {
        display: none;
    } */
    .why_chooseus-bg-img {
        background-image: none;
        height: 70px;
    }

    /* .benefits div {
        display: none;
    } */
    .package-row {
        padding-left: 0px !important;
        margin-left: -30px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        padding: 0px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .msp-btm-p-last {
        padding-top: 38px;
        padding-left: 0px;
    }

    .msp-btm-p-last-member {
        padding-top: 15px;
        padding-left: 0px;
    }

    .btn-middle-mrg {
        margin: 0px !important;
    }

    .btn_type4 {
        padding: 2px 4px 2px 4px !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 15px 0px 70px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 70px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .margin-right-9-rm {
        margin-right: 1.5rem !important;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 33% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 50% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }

    .reg_rgtimg_txt {
        float: left;
        margin: 10px 0 0 -30px;
    }

    .reg_form_heading {
        margin-top: 40px;
    }

    .heading-block-hsw {
        font-size: 20px;
        color: #0000ff;
        font-weight: 600;
        display: contents;
        float: left;
        margin-left: 25px;
    }

    .heading-block-hsw img {
        margin-top: 5px;
        margin-left: 95px;
        margin-bottom: 25px;
        width: 120px !important;
    }

    .textuppercase-why-choose {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 18px;
        font-weight: bold;
        margin-top: -10px;
    }

    .textuppercase {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 24px;
        font-weight: bold;
        margin-top: -15px;
    }

    .subtitle_text {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: -95px;
        margin-top: 20px;
    }

    .subtitle_text2 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: 1px;
    }

    .subtitle_text3 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: 1px;
    }

    .subtitle_text4 {
        font-size: 20px;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px;
        margin-top: 1px;
    }

    .p_hw {
        float: left;
        margin: 0px 25px;
    }

    .img-responsive {
        max-width: 33%;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    .why-desc {
        float: left;
        width: auto !important;
        text-align: left;
        margin: 5px 0 30px 0;
    }

    .msp-btm-p {
        padding: 0px;
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    .row-margin-n5 {
        margin-top: -5px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
        margin-left: 1px !important;
    }

    .btn_type4 {
        padding: 2px !important;
        font-size: 11px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 15px 0px 70px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 0px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
    }

    .btn-middle-mrg {
        margin-top: 0px !important;
    }

    .btn-thrd-mrg {
        margin: 0px !important;
    }

    .msp-btm-pl {
        padding: 47px 0px 0px 0px !important;
    }

    .msp-btm-pl-member {
        padding: 0px !important;
    }

    .acnt_input-2 {
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        width: 90%;
        font-size: 8px;
    }

    .acnt_input-3 {
        background-color: #eef1f5 !important;
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        color: #000 !important;
        width: 65% !important;
        margin-top: 30px !important;
        font-size: 8px !important;
    }

    .form-error {
        font-size: 7px;
    }

    .text-box_secondary3 {
        background: #fff !important;
    }

    .text-box_secondary2 {
        background: #0000ff !important;
    }

    .text-color-white2 {
        color: white !important;
    }

    .text_color_blue2 {
        color: #0000ff !important;
    }

    .calculator-hd-img {
        text-align: center;
    }

    .calculator-hd-img img {
        max-width: 50%;
    }

    .how-it-works__item {
        text-align: center;
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .pd-t20 {
        padding-top: 1px;
    }

    .ass_pur_tbl {
        margin-left: -20px !important;
    }

    #accountSetting .col-md-5 .text-uppercase {
        text-align: center;
    }

    .mobile-nav2-btn {
        display: block;
    }

    .dashboard-parcel-list {
        width: 20.9rem !important;
        margin-left: 15px;
        margin-bottom: 25px;
    }

    .orprt-mt-3,
    .orprt-my-3 {
        margin-top: 2.5rem;
    }

    .orprt_card_brd {
        border: 1px solid #00eb8c;
        min-height: 175px;
        max-width: 250px;
    }

    /* .dropdown-menu.show {
        display: block;
        top: 0px !important;
    } */
    .membership-down-grade {
        background: #000;
        color: #000;
        text-transform: uppercase;
        padding: 2px;
        font-size: 11px;
        line-height: 16px;
        min-width: auto;
        cursor: not-allowed !important;
        display: none;
    }

    .why_chooseus_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }

    .how_it_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }

    .services_card {
        min-height: auto;
        border: 2px solid #00eb8c;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .padding-left16 {
        padding-right: 16px;
    }

    .widget-inner-padding-16 {
        padding: 16px !important;
    }

    .service-prohibited-material-block-right-padding {
        padding-right: 0px !important;
    }

    .mb-card-footer {
        padding: 0.25rem 0.15rem;
    }

    .padding-15left {
        padding-left: 15px !important;
    }

    .right-block-img {
        padding: 0 0 0 9px !important;
    }

    .card-ipad-left {
        left: 115px;
    }

    .td-bg .table td {
        background-color: #f4f6f9 !important;
        border-color: #f4f6f9 !important;
    }

    #innertble > tbody > tr > td {
        background-color: #fff !important;
    }

    .innertble .table {
        margin-bottom: 0 !important;
    }
    /***********************for modal open from right*********/
    .modal.right .modal-body {
        padding: 24px 22px 22px;
    }

    .modal.right.fade .modal-dialog {
        right: -360px;
        -webkit-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -moz-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        -o-transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
        transition:
            opacity 0.3s linear,
            right 0.3s ease-out;
    }

    .modal.right.fade.show .modal-dialog {
        right: 0;
    }

    .right.modal-content {
        border-radius: 0;
        border: none;
    }

    /* ----- for modal open from right ----- */
    .margin-top5 {
        margin-top: 5px;
    }

    .margin-right10 {
        margin-right: 10px;
    }

    .only-mb-hide {
        display: none;
    }

    .modal-body-padding-mb {
        padding: 0px;
    }

    .card-body-mb {
        padding: 0px;
    }

    .responsive-margint16 {
        margin-top: 16px;
    }

    .responsive-mrgnbtm30 {
        margin-bottom: 30px;
    }

    /***************************************dashboard mobile*******************************/
    #mparcels > nav .nav-tabs {
        border-bottom: 0px solid #dee2e6;
    }

    .dashboard-container,
    .container2 {
        width: 100%;
        // padding-right: 1px;
        // padding-left: 1px;
        // margin-right: auto;
        // margin-left: auto;
        padding: 0px;
    }

    .table td,
    .table th {
        padding: 0.35rem;
    }

    #mytable > thead > tr > th:nth-child(1) {
        padding: 6px 16px 4px 16px;
    }

    #mytable > tbody > tr > td:nth-child(1) {
        padding: 10px 16px 0px 16px;
    }

    #mparcels .nav-tabs .nav-item {
        margin-bottom: -1px;
        margin-left: 0px;
        a {
            margin-bottom: -1px;
            margin-left: 0px;
        }
    }

    #mparcels .nav-link {
        display: block;
        padding: 0.25rem 0.95rem;
    }

    /***************************dashboard mobile ***********************************/
    .padding40 {
        padding: 16px;
    }

    .widget.widget_packages .widget__inner80 {
        padding: 40px 16px;
    }

    .verify-email-height {
        height: auto;
    }

    .padding250,
    .padding200,
    .paddingtop150,
    .paddingtop100 {
        padding: 50px 0px 0px 0px;
    }

    .check_icon_4 {
        margin-left: 71px;
    }

    .payment-option-checkbox {
        margin-top: -25px;
    }

    .sw-theme-default > ul.step-anchor > li {
        position: relative;
        margin-right: 1px;
        text-transform: uppercase;
        line-height: 19px;
    }

    .text_heading_blue {
        /* text-transform: uppercase; */
        color: #0000ff;
        text-align: justify;
        font-size: 2.25rem;
    }

    .pd50 {
        padding-left: 2px;
    }

    .cal-slider-heading {
        position: absolute;
        font-size: 25px;
        margin: 5px 0px 0px 100px;
    }

    .cal-slider-text {
        position: absolute;
        font-size: 12px;
        margin: 38px 2px 7px 0px;
    }

    #step-1 > form > div:nth-child(7) {
        text-align: center;
    }

    #wizard > div.content.clearfix {
        height: 66em;
    }

    .packages.row > div {
        padding-bottom: 10px;
    }

    .menu-item-resp {
        text-align: center;
    }

    /* .benefits {
        display: none;
    } */
    .description {
        width: 70%;
    }

    .calculator-hd-img {
        text-align: center;
    }

    .calculator-hd-img {
        text-align: center;
    }

    .calculator-hd-img img {
        max-width: 50%;
    }

    .tooltip-content5 {
        max-width: 39%;
        font-size: 14px;
    }

    .announcement_date_div {
        text-align: -webkit-left;
    }

    .announcement_row {
        margin-bottom: 0px;
    }

    .banner-text {
        padding: 150px 0 150px 0;
    }

    .banner-sub-heading {
        font-size: 23px;
        font-weight: 200;
        line-height: 23px;
        margin-bottom: 40px;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .why-choose-us-icon img {
        width: 100% !important;
        height: 120px !important;
    }

    .msp-btm-p {
        padding: 0px;
    }

    .row-margin-n30 {
        margin-top: -30px;
    }

    .row-margin-n5 {
        margin-top: -25px;
    }

    .how-it-work-title {
        color: #0000ff;
        font-size: 20px !important;
        line-height: 5px !important;
        font-weight: bold;
        padding-top: 10px !important;
        margin: 2 0 0 7%;
        position: relative;
        z-index: 1;
    }

    .howitworks {
        position: absolute;
        top: 60px;
        left: 60px !important;
        color: #0000ff;
        font-size: 20px !important;
        line-height: 20px !important;
        font-weight: bold;
    }

    .img-responsive {
        max-width: 33%;
    }

    .reg_line_txt {
        float: left;
        width: 100%;
        margin-left: 0px;
    }

    .package-row {
        padding-left: 0px !important;
    }

    .msp-bth {
        font-size: 10px !important;
        font-weight: 200 !important;
        line-height: 35px !important;
    }

    .package-name {
        text-align: left !important;
        padding: 5px 5px !important;
        font-size: 13px !important;
        line-height: 16px !important;
        font-weight: bold !important;
    }

    .package-price {
        display: flex !important;
        align-items: center !important;
    }

    .package-price-value {
        display: none;
    }

    .package-mb-value {
        display: block !important;
        font-weight: bold !important;
        font-size: 20px !important;
        line-height: 40px !important;
    }

    .package-price-info {
        width: auto !important;
        font-size: 9px !important;
        font-weight: 120 !important;
        padding: 4px 0px 4px 4px !important;
    }

    .reward-pkg {
        text-align: center !important;
        font-size: 30px !important;
        font-weight: 400 !important;
        color: #fff;
    }

    .package-info-item {
        padding: 1px 1px 1px 5px !important;
        position: relative;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .msp-btm-p {
        margin-top: -32px !important;
    }

    .btn-middle-mrg {
        margin-top: 5px !important;
        margin-left: -13px;
    }

    .btn_type4 {
        padding: 4px !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 16px !important;
        min-width: auto !important;
    }

    .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 12px 0px 30px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 70px 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px !important;
    }

    .btn-thrd-mrg {
        margin: 3px !important;
    }

    .msp-btm-pl {
        padding: 48px 0px 0px 0px !important;
    }

    .msp-btm-pl-member {
        padding: 0px !important;
    }

    .msp-btm-p-last-member {
        margin-top: -20px;
        padding: 0px;
    }

    .acnt_input-2 {
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        width: 70%;
        font-size: 8px;
    }

    .acnt_input-3 {
        background-color: #eef1f5 !important;
        border: none !important;
        border-bottom-color: currentcolor !important;
        border-bottom-style: none !important;
        border-bottom-width: medium !important;
        border-bottom: 1px solid #d7d7d7 !important;
        color: #000 !important;
        width: 51% !important;
        margin-top: 30px !important;
        font-size: 8px !important;
    }

    .form-error {
        font-size: 7px;
    }

    .how-it-works__item {
        text-align: center;
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .pd-t20 {
        padding-top: 1px;
    }

    #accountSetting .col-md-5 .text-uppercase {
        text-align: center;
    }

    .orprt-mt-3,
    .orprt-my-3 {
        margin-top: 3.5rem;
    }

    .ml-8,
    .mx-8 {
        margin-left: 1rem;
    }

    .ml-7,
    .mx-7 {
        margin-left: 0rem;
    }

    /* .dropdown-menu.show {
        display: block;
        top: 0px !important;
    } */
    .membership-down-grade {
        background: #000;
        color: #000;
        text-transform: uppercase;
        padding: 4px;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        min-width: auto;
        cursor: not-allowed !important;
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    /***************************************dashboard mobile*******************************/
    #mparcels > nav .nav-tabs {
        border-bottom: 0px solid #dee2e6;
    }

    .dashboard-container,
    .container2 {
        width: 100%;
        // padding-right: 1px;
        // padding-left: 1px;
        // margin-right: auto;
        // margin-left: auto;
        padding: 0px;
    }

    .table td,
    .table th {
        padding: 0.35rem;
    }

    #mytable > thead > tr > th:nth-child(1) {
        padding: 6px 16px 4px 16px;
    }

    #mytable > tbody > tr > td:nth-child(1) {
        padding: 10px 16px 0px 16px;
    }

    #mparcels .nav-tabs .nav-item {
        margin-bottom: -1px;
        margin-left: 0px;
        a {
            margin-bottom: -1px;
            margin-left: 0px;
        }
    }

    #mparcels .nav-link {
        display: block;
        padding: 0.25rem 0.95rem;
    }

    /***************************dashboard mobile ***********************************/
    .padding40 {
        padding: 16px;
    }

    .widget.widget_packages .widget__inner80 {
        padding: 40px 16px;
    }

    .verify-email-height {
        height: auto;
    }

    .padding250,
    .padding200,
    .paddingtop150,
    .paddingtop100 {
        padding: 50px 0px 0px 0px;
    }

    .check_icon_4 {
        margin-left: 100px;
    }

    .cal-slider-heading {
        position: absolute;
        font-size: 30px;
        margin: 5px 0px 0px 100px;
    }

    .cal-slider-text {
        position: absolute;
        font-size: 17px;
        margin: 60px 20px 10px 20px;
    }

    .margin-right-9-rm {
        margin-right: 1.5rem !important;
    }

    .left_heading_title {
        padding-top: 0px !important;
        float: none !important;
        margin-left: 20px !important;
        font-size: 20px !important;
    }

    .left_heading_title img {
        max-width: 45% !important;
        display: inline-block;
    }

    .left_heading_subtitle {
        float: none !important;
        margin-left: 25px !important;
    }

    .genral_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .genral_logo_mb_50 img {
        width: 60% !important;
    }

    .spng_logo img {
        width: 100% !important;
        height: 120px !important;
    }

    .reg_right_img {
        float: left;
        margin: 15px 0 0 30px;
    }

    .reg_right_img img {
        max-width: 45% !important;
        display: inline-block;
    }

    .reg_rgtimg_txt {
        float: left;
        margin: 10px 0 0 -30px;
    }

    .reg_form_heading {
        margin-top: 40px;
    }

    .heading-block-hsw {
        font-size: 20px;
        color: #0000ff;
        font-weight: 600;
        display: contents;
        float: left;
        margin-left: 25px;
    }

    .heading-block-hsw img {
        margin-top: 5px;
        margin-left: 50px;
        margin-bottom: 25px;
        width: 120px !important;
    }

    .textuppercase-why-choose {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px;
        font-size: 18px;
        font-weight: bold;
        margin-top: -10px;
    }

    .textuppercase {
        text-transform: uppercase !important;
        float: left;
        margin-left: 25px !important;
        font-size: 24px !important;
        font-weight: bold;
        margin-top: -15px !important;
    }

    .subtitle_text {
        font-size: 20px !important;
        color: #0000ff;
        font-weight: 400 !important;
        float: left;
        margin-left: 25px !important;
        margin-top: -1px !important;
    }

    .subtitle_text2 {
        font-size: 20px !important;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px !important;
        margin-top: -1px !important;
    }

    .subtitle_text3 {
        font-size: 20px !important;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px !important;
        margin-top: -1px !important;
    }

    .subtitle_text4 {
        font-size: 20px !important;
        color: #0000ff;
        font-weight: 400;
        float: left;
        margin-left: 25px !important;
        margin-top: -1px !important;
    }

    .p_hw {
        float: left;
        margin: 0px 25px !important;
    }

    .package-row {
        padding-left: 15px !important;
    }

    .mspr-bh {
        color: #fff;
        background-color: #00eb8c;
    }

    .msp-bth {
        font-size: 20px !important;
        color: #fff;
        font-weight: 400 !important;
        line-height: 65px !important;
        background-color: #0000ff;
    }

    .msp-bh {
        color: #fff;
        background-color: #00e087;
    }

    .package-name {
        color: #fff;
        text-align: center;
        padding: 20px 10px !important;
        font-size: 24px !important;
        line-height: 26px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
    }

    .package-price {
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 9px !important;
    }

    .package-price-value {
        text-align: right;
        font-size: 40px !important;
        line-height: 40px !important;
        font-weight: 500 !important;
        padding-left: 40px !important;
    }

    .package-price-info {
        width: auto;
        text-transform: uppercase;
        line-height: 11px !important;
        font-size: 10px !important;
        font-weight: 400 !important;
        padding-left: 8px !important;
        padding-top: 2px !important;
    }

    .reward-pkg {
        text-align: center;
        font-size: 50px !important;
        font-weight: 700 !important;
        color: #fff;
    }

    .col-gry {
        background-color: #eef1f5;
    }

    .package-info-item {
        padding: 15px 10px 15px 0px !important;
        position: relative;
    }

    .msp-btm-p {
        padding: -1px 0px 25px 0px !important;
    }

    .icon-check-circle-size {
        font-size: 1.5rem !important;
    }

    .col-gry-padding {
        padding: 0px !important;
    }

    .col-wht-padding {
        padding: 0px !important;
    }

    .package-mb-value {
        display: none;
    }

    .btn-middle-mrg {
        margin-top: 22px !important;
        margin-left: 0px !important;
    }

    .btn-thrd-mrg {
        margin-top: 22px;
        margin-left: 0px;
    }

    /* .col-gry-padding {
        background-color: #eef1f5 !important;
        padding: 12px 0px 30px 0px !important;
    }

    .col-wht-padding {
        background-color: #fff !important;
        padding: 15px 0px 70px 0px !important;
    } */
    .msp-btm-pl {
        padding: 49px 0px 0px 0px;
    }

    .msp-btm-pl-member {
        padding: 0px !important;
    }

    .margin-btm-18 {
        margin-bottom: -18px !important;
    }

    .package-info-item p {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .acnt_input-2 {
        border: none;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-bottom: 1px solid #d7d7d7;
        width: 90%;
    }

    .acnt_input-3 {
        background-color: #eef1f5;
        border: none;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-bottom: 1px solid #d7d7d7;
        color: #000;
        width: 79%;
    }

    .btn_type4 {
        color: #fff;
        background: #0000ff;
        text-transform: uppercase;
        padding: 10px 5px 10px 5px;
        font-weight: 300;
        font-size: 18px;
        line-height: 16px;
        min-width: auto;
    }

    .calculator-hd-img {
        text-align: center;
    }

    .calculator-hd-img img {
        max-width: 50%;
    }

    .how-it-works__item {
        text-align: center;
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .pd-t20 {
        padding-top: 1px;
    }

    .orprt-mt-3,
    .orprt-my-3 {
        margin-top: 3.5rem;
    }

    .ml-8,
    .mx-8 {
        margin-left: 1rem;
    }

    .ml-7,
    .mx-7 {
        margin-left: 0rem;
    }

    /* .dropdown-menu.show {
        display: block;
        top: 0px !important;
    } */
    .membership-down-grade {
        background: #000;
        color: #000;
        text-transform: uppercase;
        padding: 10px 5px 10px 5px;
        font-weight: 300;
        font-size: 18px;
        line-height: 16px;
        min-width: auto;
        cursor: not-allowed !important;
        display: none;
    }
}

.btnclr {
    height: 44px !important;
    width: 44px !important;
}

.fs-24 {
    font-size: 24px !important;
}

/*
Tablet size
 */
@media (min-width: 760px) and (max-width: 1024px) {
    .tab-show {
        display: block !important;
    }
    .tab-hide {
        display: none !important;
    }
    .tablet-padding-16 {
        padding: 16px !important;
    }
    .tab-top-no-padding {
        padding-top: 0 !important;
    }
    .padding-vertical-25 {
        padding: 25px 0 !important;
    }
    .tab-minus-margin-top-20 {
        margin-top: -20px !important;
    }
    .mb-show {
        display: contents;
    }

    .tab-margin-top-26 {
        margin-top: 26px !important;
    }

    .tab-margin-bottom-36 {
        margin-bottom: 36px !important;
    }

    .tab-margin-bottom-113 {
        margin-bottom: 113px !important;
    }

    .membership-plan-component.premium .popular-highlighter {
        background: #fa9218;
        border-radius: 100px;
        color: white;
        padding: 5px 0px 5px 0px;
        width: 36%;
        position: absolute;
        top: -3%;
        left: 32%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
    }

    .membership-plan-component button {
        background: #ffffff;
        border: 1px solid #c3cbd4;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #243548;
        padding: 10px 25px 10px 25px !important;
    }

    .membership-plan-component p {
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #243548;
    }

    .mobile-pay-now {
        // position: fixed;
        bottom: 0;
        z-index: 90;
        width: 100%;
        background: #fff;
        // padding: 0 20px 10px 20px;
        // margin-left: -517px !important;
    }

    .paypal-ipad {
        // margin-left: -50px !important;
        text-align: center;
    }

    .mobile-text-align-center {
        text-align: center;
    }
    .mobile-show-subtotal {
        display: block;
    }
    .mobile-width-50-percent {
        width: 50% !important;
    }

    .mobile-subtotal-padding {
        padding: 20px;
    }
}
.tab-show {
    display: none;
}

@media only screen and (min-device-width: 766px) and (max-device-width: 1023px) {
    .socials ul {
        justify-content: center;
    }

    .ipad-hide-col {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center !important;
    }

    .ipad-hide {
        display: none;
    }

    .mobile-nav-btn {
        top: -10px;
    }

    .mobile-nav-btn {
        display: block;
    }

    .main-nav {
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;
        padding-top: 15px;
    }

    .main-nav > ul {
        display: block;
        width: 100%;
    }

    .usr-nav {
        display: inherit;
        top: -109px;
        left: 10px;
        padding-top: 5px;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .mobile-nav-btn {
        display: block;
    }

    .mobile-nav-active .header__bottom {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        margin: 0px 0px 20px 0px;
        width: 100%;
        background: #fff;
    }

    .ipad-show-hide {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        margin: 0px 0px 20px 0px;
        width: 100%;
        background: #fff;
    }

    .container,
    .custom-container {
        max-width: auto;
    }

    .dashboard-container,
    .container2 {
        width: 100%;
        padding: 0px;
    }

    .ipad-dashboard .col-md-9.col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .ipad-dashboard .col-md-3 {
        -ms-flex: 0 0 97%;
        flex: 0 0 97%;
        max-width: 100%;
    }

    .ipad-dashboard .row-bg-white {
        background-color: #fff;
    }

    .ipad-left {
        text-align: center !important;
    }

    .ipad-padding-bottom-24 {
        padding-bottom: 24px;
    }

    .ipad-padding-top-24 {
        padding-top: 24px;
    }

    .ipad-margin-top-50-percent {
        margin-top: 50%;
    }

    .ipad-margin-top-25-percent {
        margin-top: 25%;
    }
}

@media only screen and (min-device-width: 1024px) {
    .service-block {
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-device-width: 1023px) {
    .service-block {
        align-items: center;
    }
}

.margin-0 {
    margin: 0 !important;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) {
    .wd30 {
        -ms-flex: 0 0 40% !important;
        flex: 0 0 40% !important;
        max-width: 40% !important;
    }
}

@media only screen and (min-device-width: 580px) and (max-device-width: 764px) {
    .wd30 {
        -ms-flex: 0 0 45% !important;
        flex: 0 0 45% !important;
        max-width: 45% !important;
    }

    .img-home-top {
        width: 100%;
        height: auto;
        margin-left: -1px;
        margin-top: 50px;
    }

    .popular-highlighter {
        width: 20%;
    }

    .mb-show,
    .mb-show-block {
        display: block !important;
    }

    .nav-link {
        display: block;
        padding: 0.8rem 3.1rem;
    }

    .content {
        background: #ffffff;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.mobile-width-53-percent {
    width: 53% !important;
}

.mobile-width-47-percent {
    width: 47% !important;
}

.font-1vw {
    font-size: 0.95vw !important;
}

@media (max-width: 1199px) {
    .font-1vw {
        font-size: 1.3vw !important;
    }
}
