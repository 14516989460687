.payment-card {
    text-align: start;
    .card-body {
        width: 90%;
        padding: 3rem 1.25rem;
    }
}

.payment-stripe-checkout {
    .form-group {
        margin-bottom: 1.5rem;
    }
}

.card-list {
    border: 1px solid #e4e8f1;
    box-sizing: border-box;
    .form-group {
        margin-bottom: 0px;
    }
    .new-card-container {
        padding: 1.5rem;
        .form-group {
            margin: 1rem 0 1rem 0;
        }
    }
}

.saved-card-container {
    width: 100%;
    height: 60px;

    background: #ffffff;
    &.new-card-option-selected {
        background: #f4f6f9;
    }

    border-bottom: 1px solid #e4e8f1;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    font-size: 16px;

    .form-check {
        display: flex;
        align-items: center;

        label {
            margin-left: 12px;
        }

        .saved-card-details {
            display: flex;
            flex-flow: column;

            span {
                font-size: 16px;
            }

            .name-on-card {
                font-size: 12px;
                line-height: 160%;
                color: #8798a9;
            }
        }
    }
}

.padding-3 {
    padding-inline: 1.57rem;
}
