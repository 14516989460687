@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.title {
    font-weight: 500;
    font-size: 24px;
    margin-top: 38px;
    margin-bottom: 26px;
    @include media-breakpoint-up(lg) {
        margin-top: 31px;
        margin-bottom: 29px;
    }
}
