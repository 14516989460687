@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.e2e-solutions {
    background-color: #0000ff;
    margin-bottom: 10px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    padding: 8px calc(min(5.556%, 14px)) 8px calc(min(5.556%, 20px));
    @include media-breakpoint-up(md) {
        padding: 8px calc(min(22.22%, 98px)) 8px 30px;
        max-width: 441px;
        width: 91%;
    }
}

.cross-border {
    background-color: #22d180;
    margin-bottom: 10px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    padding: 8px calc(min(5.556%, 20px));
    @include media-breakpoint-up(md) {
        padding: 8px calc(min(22.22%, 68px)) 8px 30px;
        max-width: 441px;
        width: 91%;
    }
}

.list-item {
    font-size: 22px;
}

.pb-44px {
    padding-bottom: 44px !important;
    @include media-breakpoint-up(md) {
        padding-bottom: unset;
    }
}
