@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.smart-tracking {
    padding: 53px 0;

    @include media-breakpoint-up(md) {
        padding: 46px 0;
    }
}

.contents {
    max-width: calc(min(89.722%, 323px));
    margin: 0 auto 74px auto;
    @include media-breakpoint-up(md) {
        max-width: unset;
        margin: unset;
        margin-left: calc(min(124px, 8.611%));
    }
}

.image {
    min-width: 287px;
    width: 100%;

    @include media-breakpoint-up(md) {
        max-width: 580px;
        width: 40.278%;
        max-width: unset;
        margin: unset;
    }
}
