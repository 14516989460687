.directory-footer-container {
    padding: 47px 30px;
    background-color: #00eb8c;
    font-weight: 600;
    font-size: 34px;

    .text-blue {
        color: #0000ff;
    }
}
