.card-container {
    padding: 17px 0 0 30px;
    margin-bottom: 33px;
    width: 300px;
    min-height: 90px;
    background-color: #ffffff;
    border-radius: 9px;
}

.card-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px;
    width: 40px;
    height: 40px;
}

.card-content {
    font-weight: 600;
    font-size: 20px;
}
