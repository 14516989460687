@import '../../constants/constants.css';

.widget {
    .tab-content {
        display: flex;
        justify-content: center;
    }
    .pending-parcel-msg-ar {
        margin-right: 2.5rem;
    }
    .pending-parcel-msg-en {
        margin-left: 2.5rem;
    }
    .mobile-count-margin-right {
        margin-right: 2.8rem;
    }
    .mobile-count-margin-left {
        margin-left: 2.8rem;
    }
}

.parcel-accordion-items {
    padding: 0.5rem 0rem 0.5rem 0rem;
    background-color: #fff;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    border: 1px solid #e4e8f1;
    overflow: hidden;
    div {
        // display: contents;
    }
}

.parcel-table {
    tr {
        &.selected-row {
            background: #fafaff;
        }
    }
    .form-check-input {
        margin-top: 0px;
        height: 16px !important;
        width: 16px !important;
    }
    th {
        vertical-align: middle !important;
    }
    td {
        height: auto;
        border-bottom: 1px solid #dee2e6;
    }
    .parcel-accordian {
        td {
            border: none;
        }
        .ship-now-btn {
            margin: 15px 0px;
            display: flex;
            flex-flow: column;
            justify-content: right;
            align-items: flex-end;
            button {
                min-width: 175px;
                width: 220px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                min-height: 38px;
            }
        }
    }
}
.incoming-orders-container {
    margin-top: -7.8rem;
    .widget .widget__inner {
        #incoming-orders {
            #nav-tabContent {
                .bx-info-circle {
                    font-size: 20px;
                    padding-bottom: 0.2rem;
                }
                button {
                    min-width: 220px;
                    min-height: 38px;
                }
            }
            .nav {
                .nav-item {
                    margin-top: 8px;
                    .nav-link {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                        height: 44px;
                        &:not(.active) {
                            background: #f4f6f9;
                            border: 1px solid #e4e8f1;
                            margin-bottom: 0px;
                        }
                        &.active {
                            background-color: #ffffff !important;
                        }
                    }
                }
            }
            .tab-pane {
                background-color: #ffffff !important;
                display: none;
                &.active {
                    display: block;
                }
            }
            .parcel-pagination {
                display: inline-flex;
                float: right;
                flex-wrap: wrap;
            }
        }
    }
}

a.order-details-link {
    color: #0000ff !important;
    text-decoration: none;
}

.incoming-orders-container-wrapper {
    background: #eef1f5;
    padding-bottom: 25px;
    .carousel-banner {
        .carousel-indicators {
            display: none;
        }
    }
    .desktop {
        .carousel-inner {
            height: 330px !important;
            .imageSet-container-banner {
                min-height: 270px;
            }
        }
        &.carousel-banner {
            .carousel-indicators {
                bottom: 0px !important;
            }
        }
    }

    .incoming-orders-request-container {
        .search-request-button {
            .btn {
                background: #ffffff !important;
                border: 1px solid #0000ff !important;
                box-sizing: border-box;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03) !important;
                border-radius: 6px !important;
                color: #0000ff !important;
                text-transform: capitalize;
            }
        }
        .create-new-request-button {
            .btn {
                background: #0000ff !important;
                border: 1px solid #0000ff !important;
                box-sizing: border-box;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03) !important;
                border-radius: 6px !important;
                color: #fff !important;
                text-transform: capitalize;
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
            }
        }
    }
    .react-bootstrap-table {
        .w-100-px {
            max-width: 100px;
        }
        .w-130-px {
            max-width: 130px;
        }
        .w-200-px {
            width: 200px;
        }
        .w-250-px {
            width: 250px;
        }
        .w-290-px {
            width: 290px;
        }
        .w-300-px {
            width: 300px;
        }
        .w-320-px {
            width: 320px;
        }
        .w-330-px {
            width: 330px;
        }
        .tbl-hd-bg {
            th {
                vertical-align: middle !important;
                font-style: normal;
                font-weight: normal;
                color: #8798a9;
                height: 40px;
            }
        }
        .row-class {
            td {
                border-bottom: 1px solid #dee2e6;
                &.expand-cell {
                    width: 20px;
                    i {
                        color: #0000ff;
                    }
                }
                &.date-format {
                    direction: ltr;
                    text-align: end;
                }

                .status {
                    .status-icon {
                        height: 8px;
                        width: 8px;
                        background-color: #bbb;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 5px;

                        &.status-completed {
                            background: #22d180;
                        }
                        &.status-reject {
                            background: #ee2e24;
                        }
                        &.status-ready {
                            background: #0000ff;
                        }
                        &.status-in-progress {
                            background: #ecc94b;
                        }
                        &.status-wallet-request {
                            background: #ff8f6b;
                        }
                    }
                    .status-text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: #243548;
                    }
                    .status-text-expand {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        color: #243548;
                    }
                }
            }
        }
    }
    .react-bootstrap-table-pagination {
        display: none !important;
        .react-bootstrap-table-pagination-list {
            .pagination {
                float: right !important;
            }
        }
    }
}

.dashboard-table {
    padding-right: 32px;
}

.widget {
    padding-bottom: 15px;
}

.gray-bg {
    background: #f4f6f9;
}

div.modal div.modal-dialog div.modal-content {
    div.border-right-gray {
        border-right: 1px solid #f4f6f9;
    }
    div.border-bottom-blue {
        border-bottom: 2px solid #0000ff;
    }

    div.w-fit {
        width: fit-content !important;
        margin: 0;
    }
    a.minus {
        background: #e4e8f1;
        font-size: 1rem;
        padding: 0.15rem 0.6rem;
    }
    span.text-blue {
        padding: 0.15rem 0.6rem !important;
    }
    a.plus {
        background: #0000ff;
        padding: 0.15rem 0.6rem;
        font-size: 1rem;
        .rounded-top-right-1 {
            border-top-right-radius: 1rem !important;
        }
        .rounded-bottom-right-1 {
            border-bottom-right-radius: 1rem !important;
        }
        color: #ffffff !important;
    }
    .text-grey-light {
        color: #8798a9;
    }
}
.incoming-orders-request-modal {
    &.modal-dialog div.modal-content {
        .modal-header {
            border-bottom: 0px;
            .close {
                padding: 5px 10px;
                font-size: 30px;
            }
        }
        .modal-footer {
            border-top: 0px;
        }
        .modal-body {
            .product-detail {
                .currency-prefix {
                    position: absolute;
                    color: #2d3748;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    opacity: 0.3;
                }
                .browse-field {
                    width: 80%;
                    border: 1px solid #e4e8f1;
                    padding: 9px;
                }
                .browse-button {
                    width: 20%;
                    background: linear-gradient(0deg, #f4f6f9, #f4f6f9), #ffffff;
                    border: 1px solid #e4e8f1;
                    box-sizing: border-box;
                    padding: 10px;
                    text-align: center;
                }
                .comments-textarea {
                    border: 1px solid #e4e8f1;
                    box-sizing: border-box;
                }
            }
        }
    }
}
.en {
    .incoming-orders-container {
        margin-top: -1.8rem;
        .incoming-orders-request-container {
            .table-container {
                .row-class {
                    .expand-cell {
                        padding-left: 0px;
                    }
                    .status {
                        direction: ltr;
                        .btn {
                            float: right;
                        }
                    }
                }
                .react-bootstrap-table {
                    .reset-expansion-style {
                        .cancel-request {
                            direction: rtl;
                        }
                        .desc {
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
        .desktop_requests {
            .incoming-orders-request-container {
                .create-new-request-button {
                    // position: absolute;
                    // top: -45px;
                    right: 15px;
                }
            }
        }
        .mobile_requests {
            .incoming-orders-request-container {
                .request-button {
                    margin-top: 10px;
                }
            }
        }
    }

    &.incoming-orders-request-modal {
        &.modal-dialog div.modal-content {
            .product-detail {
                .browse-field {
                    border-right: 0px;
                }

                .form-label {
                    &.label-width {
                        padding-right: 0px !important;
                        &:first-child {
                            padding-left: 0px !important;
                        }
                        .currency-prefix {
                            top: 42px;
                            left: 10px;
                        }
                        .currency-prefix-space {
                            left: 25px;
                        }
                        .add-space {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }
}
.ar {
    .incoming-orders-container {
        margin-top: -1.8rem;
        .incoming-orders-request-container {
            .table-container {
                .row-class {
                    .expand-cell {
                        padding-right: 0px;
                    }
                    .status {
                        direction: rtl;
                        .btn {
                            float: left;
                        }
                    }
                }
                .react-bootstrap-table {
                    .reset-expansion-style {
                        .cancel-request {
                            direction: ltr;
                        }
                        .desc {
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
        .desktop_requests {
            .incoming-orders-request-container {
                .create-new-request-button {
                    // position: absolute;
                    // top: -45px;
                    left: 15px;
                }
            }
        }
        .mobile_requests {
            .incoming-orders-request-container {
                .request-button {
                    margin-top: 10px;
                }
            }
        }
    }

    &.incoming-orders-request-modal {
        &.modal-dialog div.modal-content {
            .product-detail {
                .browse-field {
                    border: 1px solid #e4e8f1;
                }
                .form-label {
                    &.label-width {
                        padding-left: 0px !important;
                        &:first-child {
                            padding-right: 0px !important;
                        }
                        .currency-prefix {
                            top: 42px;
                            right: 10px;
                        }
                        .currency-prefix-space {
                            right: 25px;
                        }
                        .add-space {
                            padding-right: 25px;
                        }
                    }
                }
            }
        }
    }
}
// tablet
.brand-card {
    background: #f6f6f6;
}

.react-bootstrap-table-pagination-total {
    margin-inline: 1.5rem;
    margin-top: -17px;
}

.h-full .ReactFlagsSelect-module_selectOptions__3LNBJ {
    max-height: 150px !important;
}

@media only screen and (min-device-width: 766px) and (max-device-width: 1023px) {
    .incoming-orders-container {
        width: 100%;
        padding: 0px;
    }

    .dashboard-row .col-md-9.col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .dashboard-row .row-bg-white {
        background-color: #fff;
    }
}

@media (min-width: 575px) and (max-width: 1200px) {
    .en {
        &.incoming-orders-request-modal {
            &.modal-dialog div.modal-content {
                .product-detail {
                    .form-label {
                        &.label-width {
                            &.remove-space {
                                padding-left: 0px !important;
                            }
                            .currency-prefix-space {
                                left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ar {
        &.incoming-orders-request-modal {
            &.modal-dialog div.modal-content {
                .product-detail {
                    .form-label {
                        &.label-width {
                            &.remove-space {
                                padding-right: 0px !important;
                            }
                            .currency-prefix-space {
                                right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .en {
        &.incoming-orders-request-modal {
            &.modal-dialog div.modal-content {
                .product-detail {
                    .form-label {
                        &.label-width {
                            &.remove-space,
                            &.remove-space-mobile {
                                padding-left: 0px !important;
                            }

                            .currency-prefix-space {
                                left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ar {
        &.incoming-orders-request-modal {
            &.modal-dialog div.modal-content {
                .product-detail {
                    .form-label {
                        &.label-width {
                            &.remove-space,
                            &.remove-space-mobile {
                                padding-right: 0px !important;
                            }
                            .currency-prefix-space {
                                right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// mobile

@media (min-width: 320px) and (max-width: 765px) {
    .incoming-orders-container {
        width: 100%;
        padding: 0px;
        margin-top: 3rem;
    }
}

@media only screen and (min-width: 1200px) {
    .incoming-orders-container-wrapper {
        .banner-text-position-en {
            left: 5.5rem;
        }

        .banner-text-position-ar {
            right: 5.5rem;
        }

        .banner-img-margin-en {
            margin-right: 1.3rem;
        }

        .banner-img-margin-ar {
            margin-left: 1.3rem;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        .incoming-orders-container {
            margin-top: -7.8rem;
        }
    }
    .incoming-orders-request-modal {
        &.modal-dialog div.modal-content {
            .product-detail {
                .form-label {
                    &.label-width {
                        flex: auto;
                        max-width: 20%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 769px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .xl-font {
                font-style: normal;
                font-weight: bold !important;
                font-size: 3vw !important;
            }
            .banner-text-position-ar,
            .banner-text-position-en {
                margin-top: 40px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                img {
                    &.image0 {
                        margin-top: 12rem !important;
                    }
                }
            }
            .banner-text-position-en-mobile {
                text-align: center !important;
                max-width: 100% !important;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 80px;
            }
        }
    }
}
@media only screen and (min-width: 990px) and (max-width: 1199px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 70px;
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 60px;
            }
        }
    }
}
@media only screen and (max-width: 1440px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .banner-img-margin-en {
                margin-right: 0rem;
            }

            .banner-img-margin-ar {
                margin-left: 0rem;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                img {
                    &.image0 {
                        margin-top: 12rem !important;
                    }
                }
            }
        }
    }
}
.incoming-orders-container-wrapper {
    .carousel-inner {
        .xl-font {
            max-width: 100% !important;
        }
        .imageSet-container-banner {
            img.image1 {
                max-width: 100% !important;
            }
        }
    }
}

.custom-alert-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #fff;
    color: #000;
    box-shadow: 0 20px 75px #ccc;
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: #243548;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        color: #66727f;
    }
    .cancel-button {
        background: #fdeae9;
        color: #ee2e24;
        border: 1px solid #fdeae9;
        border-radius: 6px;
        width: 180px;
        height: 48px;
        margin: 10px;
    }
    .deduct-button {
        background: #0000ff;
        color: #fff;
        border: 1px solid #0000ff;
        border-radius: 6px;
        width: 180px;
        height: 48px;
        margin: 10px;
    }
}
@media (max-width: 887px) and (min-width: 768px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .banner-text-position-en {
                margin-top: 54px;
                .xl-font {
                    font-size: 22px !important;
                }
            }
        }
    }
}
@media (max-width: 768px) and (min-width: 450px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            height: 200px !important;
            .imageSet-container-banner {
                justify-content: end !important;
                img.image0 {
                    width: 300px !important;
                    margin-top: 15px !important;
                }
            }
            .banner-text-position-en-mobile .banner-text-position-ar-mobile {
                width: 300px !important;
                font-size: 10px !important;
                min-width: 150px !important;
                top: 10px;
                .xl-font {
                    font-size: 18px !important;
                    margin-inline-start: 1rem;
                    text-align: start !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .incoming-orders-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                justify-content: end !important;
                img.image0 {
                    width: 200px !important;
                    margin-top: 30px !important;
                }
            }
            .banner-text-position-en-mobile .banner-text-position-ar-mobile {
                width: 100px !important;
                font-size: 10px !important;
                min-width: 78px !important;
                top: 17px;
                .xl-font {
                    font-size: 14px !important;
                    text-align: start !important;
                }
            }
        }
    }
}

.en {
    .react-bootstrap-table {
        .row-class {
            td {
                &.date-format {
                    direction: ltr;
                    text-align: start;
                }
            }
        }
    }
}

.ar {
    .react-bootstrap-table {
        .row-class {
            td {
                &.date-format {
                    direction: ltr;
                    text-align: end;
                }
                .total-amount {
                    direction: ltr;
                    text-align: end;
                }
            }
        }
        .amount-dir {
            direction: ltr;
            text-align: end;
        }
    }
    .product-quantity {
        direction: ltr;
        flex-direction: row-reverse !important;
    }
}
