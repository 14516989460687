@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.schedule-a-call {
    background-color: #ffffff;
    padding-bottom: 15px;
    @include media-breakpoint-up(md) {
        padding-bottom: 27px;
    }
}

.contents {
    max-width: calc(min(89.722%, 321px));
    margin: 0 auto;
    margin-top: calc(min(23px, 15%));
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
        max-width: unset;
        margin-top: calc(min(77px, 5.347%));
        margin-bottom: unset;
    }
}

.image {
    min-width: 360px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 46.32%;
        max-width: 667px;
    }
}
