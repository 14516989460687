@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.us-warehouse {
    .prohibited-materials-wrapper {
        // preserve aspect ratio
        position: relative;
        max-width: 673px;
        @include media-breakpoint-up(sm) {
            max-width: 1066px;
        }

        @include media-breakpoint-up(sm) {
            transform: translate(-5%, calc(min(-94px, -30%)));
        }

        &:before {
            content: '';
            display: block;
            background-image: url('./every-thing-mobile.svg');
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            padding-top: calc((472px * 2) - 100vw);

            @include media-breakpoint-up(sm) {
                background-image: url('./every-thing.svg');
                background-position: center;
                padding-top: 37.45%; // aspect ratio
                background-size: 100% 100%;

                @media (max-width: 1060px) {
                    background-size: cover;
                    padding-top: calc(((1060px * 2) - 100vw) * 0.37);
                }
            }
        }

        > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @include media-breakpoint-up(sm) {
                display: flex;
                align-items: center;
                padding: #{'min(15vw, 30px)'} #{'min(10vw, 50px)'} 0 #{'min(22vw, 100px)'};
            }

            @include media-breakpoint-up(lg) {
                padding: #{'min(15vw, 30px)'} #{'min(12vw, 200px)'} 0 #{'min(22vw, 350px)'};
            }

            &.rtl {
                text-align: right !important;
            }

            .pm-font-size {
                font-size: 24px;

                @include media-breakpoint-up(sm) {
                    font-size: 28px;
                }
            }
        }
    }
}
