@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.directory-page {
    .stores-container {
        padding: 44px 14px 54px 14px;
        margin: 0 auto;
        max-width: 1445px;
        @include media-breakpoint-up(lg) {
            padding: 59px 39px 69px 39px;
        }

        .filters-container {
            margin-bottom: 39px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 56px;
            }
        }

        .filters {
            text-align: center;
            .custom-outlined-input {
                border: none;
                background-color: #ffffff;
                border-radius: 13px;
                font-weight: 500;
                font-size: 14px;
                color: #243548;
                width: 200px;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none !important;
            }

            .left-arrow {
                .MuiSelect-icon {
                    right: auto !important;
                    left: 7px !important;
                }
            }
        }

        .stores-title {
            font-weight: 600;
            font-size: 30px;
            color: #243548;
            margin-bottom: 7px;
            text-align: center;
            margin: 0 15px 7px 15px;
            @include media-breakpoint-up(lg) {
                font-size: 34px;
                margin: 0 15px;
                text-align: unset;
            }
        }

        .tab-bar {
            display: inline-block;
            background-color: #ffffff;
            border-radius: 18px;
            color: #243548;
            font-weight: 500;
            font-size: 14px;
            padding: 5px 7px;
            margin-bottom: 21px;
            text-align: center;
            max-width: 300px;

            @media (min-width: 504px) {
                max-width: none;
            }

            @media (min-width: 991px) {
                max-width: 450px;
            }

            @media (min-width: 1092px) {
                max-width: none;
            }

            @include media-breakpoint-up(lg) {
                font-size: 18px;
                padding: 7px 10px;
                border-radius: 60px;
                margin-bottom: 0;
                // max-width: 450px;
            }

            > a {
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: bottom; // fix extra space cause by hidden overflow
                border-radius: 72px;
                transition:
                    color 300ms,
                    background-color 300ms;
                padding: 9px 23px;
                @include media-breakpoint-up(lg) {
                    padding: 15px 34px;
                }
                &:hover {
                    color: #0000ff;
                }
                &.active {
                    background-color: #0000ff;
                    color: white;
                }
            }
        }

        .tab-content {
            text-align: center;
            .active {
                background-color: unset;
            }
        }

        .categories-multi-select-side {
            margin: 0 22px;
            padding: 20px 13px 30px 13px;
            background-color: #ffffff;
            border-radius: 13px;
            min-width: 200px;

            .categories-title {
                font-weight: 500;
                font-size: 22px;
                color: #66727f;
                padding: 0 12px;
            }
        }

        .checkbox-label-font {
            font-family: 'Kanit' !important;

            &.ar {
                font-family: 'Cairo' !important;
            }
        }
    }
}
