.global-section-container {
    .container {
        &.wallet-container {
            min-height: 300px;
            .wallet-heading {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: #243548;
            }
            .wallet-price {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #22d180;
            }
            .table-container {
                .react-bootstrap-table {
                    .tbl-hd-bg {
                        th {
                            vertical-align: middle !important;
                            font-style: normal;
                            font-weight: normal;
                            color: #8798a9;
                            height: 40px;
                            border-bottom: none;
                        }
                    }
                    .row-class {
                        td {
                            border-bottom: 1px solid #dee2e6;
                            .total-amount {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 160%;
                                .add-sign {
                                    color: #22d180;
                                }
                                .amount-val {
                                    color: #243548;
                                }
                            }
                        }
                    }
                }
            }
            .react-bootstrap-table-pagination-list {
                .pagination {
                    float: right !important;
                }
            }
        }
    }
}
