@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.shopini-logistics {
    font-weight: 500;
    font-size: 8px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
    @include media-breakpoint-up(sm) {
        font-size: 12px;
    }
}

.are-you-retailer {
    max-width: 316px;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 600;
    color: #0000ff;
    @include media-breakpoint-up(sm) {
        max-width: 648px;

        margin-bottom: 12px;
        font-size: 45px;
    }
}

.upscale-business {
    max-width: 250px;
    margin-bottom: 24px;
    font-size: 22px;
    @include media-breakpoint-up(sm) {
        max-width: 470px;
        margin-bottom: unset;
        font-size: 42px;
    }
}
