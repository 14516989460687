.directory-page {
    > .service-section {
        background-color: #ffffff !important;

        .card {
            background-color: #ffffff !important;
        }

        .title {
            margin-bottom: 49px;
        }
    }
}
