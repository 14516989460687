@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

body {
    font-family: Arial, Helvetica, sans-serif;
}
* {
    box-sizing: border-box;
}

.Calculate {
    div.card {
        background: #f4f6f9;
    }
}

div.calculater {
    div {
        div {
            label {
                font-weight: 100 !important;
            }
        }
    }
}
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.main-subtitle-banner {
    margin: 15px 1.5rem;
    text-align: left !important;
    font-weight: 500;
}
.max-width {
    width: 100%;
}
.box-tile-main {
    font-size: 2.125rem;
    margin: 0;
    color: #0000ff;
    font-weight: bold;
}
.box-sub-tile-main {
    font-size: 1.625rem;
    margin-bottom: 15px;
}

.form-inline label {
    margin: 5px 10px 5px 0;
}

.label-direction-rtl {
    direction: ltr;
}

.label-direction-ltr {
    direction: rtl;
}

.form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.margin-zero.rtl {
    font-size: 20px;
}
.margin-zero.ltr {
    font-size: 20px;
}

.button-section {
}
.button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
}

.button:hover {
    background-color: royalblue;
}

.remove {
    background-color: rgb(192, 53, 53);
}
.form-body {
    background-color: var(--grey-background-section-color);
    border-radius: 10px;
}
.calculater {
    background-color: var(--grey-background-section-color);
    border-radius: 10px;
    margin: 15px 0px;
}

.remove {
    border: 0;
    padding: 1% 1% 0 1% !important;
    margin: 2rem;
}

.bx-x-custom {
    font-size: 25px;
    color: white;
    background-color: #ee2e24;
    border-radius: 5px;
}
.calculater {
    padding: 25px;
}
.add-button {
    font-size: 20px;
    color: #3962fe;
    padding: 20px 0px;
}

.row-format {
    margin: 15px 0px;
    padding: 15px;
}

.resuilts-box {
    background-color: #deebff;

    border-radius: 5px;
}

.w-7pr {
    width: 14%;
    padding: 0px 5px;
}
.w-12pr {
    width: 13%;
    padding: 0px 5px;
}
.form-row-dispay {
    display: contents;
}

.form-row-dispay {
    .form-group {
        text-align: start;
    }
}

.col-md-6 {
    .col {
        text-align: start;
    }
}
.gray-bg {
    background-color: #f4f6f9 !important;
}
.waite-bg {
    background: #ffffff;
}

.pm-zero {
    padding: 0px !important;
    margin: 0px;
}
.mb-20 {
    margin-bottom: 20px;
}
.margin-zero {
    .resuilts-box-w {
        font-size: 30px;
        color: #00f;
    }
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-10 {
    padding-bottom: 10px;
}

.p-25 {
    padding: 25px;
}
.shop-cost-title {
    font-size: 22px;
    text-align: left !important;
}
.shop-cost-body {
    color: #8f9193;
    text-align: left !important ;
}

.shop-cost-title.rtl {
    text-align: right !important;
}
.shop-cost-body.rtl {
    text-align: right !important;
}

.text-align-end {
    text-align: end;
}

.price-total {
    font-size: 28px;
}

.calculater {
    .container {
        max-width: 100%;
        min-width: 100%;
    }
}
.max-width-containers {
    max-width: 100%;
    min-width: 100%;
    padding: 0px 20px;
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {
    .calculater {
        .container {
            max-width: 100%;
            min-width: 100%;

            .row {
                .col-3 {
                    padding: 0px 5px;
                }
            }
        }
    }

    .box-tile-main {
        font-size: 2rem;
        margin: 0;
        color: #0000ff;
    }

    .box-sub-tile-main {
        font-size: 1.2rem;
        margin-bottom: 20px;
        padding-top: 10px;
    }

    .p-25 {
        padding: 10px;
        .row {
            flex-wrap: nowrap;
        }
    }
    .shipping-c-img {
        width: 39px;
    }
    .shop-cost-title {
        font-size: 14px;
    }
    .shop-cost-body {
        font-size: 12px;
    }
    .price-total {
        font-size: 20px;
    }

    .card-text {
        word-wrap: initial;
    }
    .resuilts-box {
        border-radius: 5px;
    }

    .total-days {
        font-size: 12px;
    }

    .pading-2px {
        padding: 2px !important;
    }

    .margin-zero.rtl {
        span {
            font-size: 18px;
        }
    }
    .margin-zero.ltr {
        font-size: 18px;
    }
    .margin-zero {
        margin: 0px;
        margin: 0px;
        padding: 5px;
        line-height: 17px;

        span {
            font-size: 17px;
        }
        .resuilts-box-w {
            font-size: 20px;
            color: #00f;
        }
    }

    .w-7pr {
        width: 27%;
        padding: 0px 2px;

        label {
            font-size: 12px;
        }
    }
    .w-12pr {
        width: 25%;
        padding: 0px 2px;
    }
    label {
        font-size: 12px;
    }
    .calculate-form {
        padding: 10px 5px;
    }
    .length-label {
        width: 20%;
    }
    .row-format {
        padding: 15px 5px;
    }
}

@media only screen and (max-width: 414px) {
    .margin-zero span {
        font-size: 14 px;
    }
    .resuilts-box {
        border-radius: 5px;
        margin-top: 10px;
    }
    .label-pading {
        font-size: 0.65rem;
    }
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) and (min-width: 595px) {
    .w-7pr {
        width: 20%;
        padding: 0px 2px;

        label {
            font-size: 15px;
        }
    }
    .w-12pr {
        width: 30%;
        padding: 0px 2px;
        label {
            font-size: 15px;
        }
    }
    .length-label {
        width: 20%;
    }
    .row-format {
        padding: 15px 5px;
    }
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .w-7pr {
        width: 20%;
        padding: 0px 2px;

        label {
            font-size: 15px;
        }
    }
    .w-12pr {
        width: 30%;
        padding: 0px 2px;
        label {
            font-size: 15px;
        }
    }
    .length-label {
        width: 20%;
    }
    .row-format {
        padding: 15px 5px;
    }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
    .label-pading {
        padding: 0px 3px;
    }
    .form-body.row-format {
        padding: 15px;
    }
    .calculater {
        .container {
            max-width: 100%;
            min-width: 100%;

            .row {
                .col-lg-2 {
                    padding: 0px 5px;
                }
                .col-lg-1 {
                    padding: 0px 5px;
                }
            }
        }
    }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .calculater {
        .container {
            max-width: 100%;
            min-width: 100%;

            .row {
                .col-lg-2 {
                    padding: 0px 5px;
                }
                .col-lg-1 {
                    padding: 0px 5px;
                }
            }
        }
    }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
    .calculater {
        .container {
            max-width: 100%;
            min-width: 100%;

            .row {
                .col-lg-2 {
                    padding: 0px 5px;
                }
                .col-lg-1 {
                    padding: 0px 5px;
                }
            }
        }
    }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
}
.delete-style {
    margin: 2.1rem -2rem;
}

.calculater-div {
    div.gray-bg {
        margin-top: 0px !important;
        padding: 30px 3px 0px 0px;
    }
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    max-height: 250px !important;
}

.close-btn {
    background-image: url('./close.svg');
    background-size: contain;
    min-width: 22px;
    min-height: 22px;
}

.weights-background {
    background-color: #deebff;
}

.weight-box {
    background-color: #deebff;
    border-radius: 5px;
}

.faq-div {
    background-image: url('./faq.svg');
    background-size: contain;
    width: 100px;
    height: 100px;
}

button {
    &.no-opacity {
        opacity: 1;
    }
}

.shipping-made-simple {
    margin-bottom: 15px;
    font-size: 24px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 28px;
        font-size: 34px;
    }
}

.where-do-you-want {
    margin-bottom: 15px;
    font-size: 22px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 28px;
        font-size: 26px;
    }
}

.no-results-container {
    padding-bottom: 50px;

    .no-results-img {
        width: 100px;
        height: 100px;
        background-image: url('./no-results.svg');
        background-size: contain;
    }

    .no-results-custom-font-1 {
        font-weight: 500;
        font-size: 26px;
    }

    .no-results-custom-font-2 {
        font-weight: 400;
        font-size: 16px;
    }
}

.cursor-pointer {
    cursor: pointer;
}
