.w-15 {
    width: 15%;
    align-self: start;
}

.country-name {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    // color: #243548;
}

.map {
    margin: 3rem 9.188rem;
}

.address-card {
    background-color: white;
}

.ReactFlagsSelect-module_label__27pw9 {
    margin-right: 0.5rem;
}

.swiper-button-next {
    position: static !important;
    float: right !important;
    margin-right: 9rem;
}

.swiper-button-prev {
    position: static !important;
    float: left !important;
    margin-left: 9rem;
}
