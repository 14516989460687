@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.shopini-logistics {
    font-weight: 500;
    font-size: 18px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
}

.title {
    max-width: 583px;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 600;
    color: #0000ff;
    @include media-breakpoint-up(md) {
        margin-bottom: 14px;
        font-size: 28px;
    }
}

.sub-title {
    font-size: 22px;
    font-weight: 500;
    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
}
