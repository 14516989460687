.seller {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 42.264%;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('../../../images/solutions-seller.svg');
}
