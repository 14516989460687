@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.international-shipping {
    padding-top: 25px;
    background-color: #ffffff;
    @include media-breakpoint-up(md) {
        max-width: unset;
        padding: 34px;
    }
}

.title {
    max-width: calc(min(89.722%, 330px));
    margin: 0 auto 51px auto;
    @include media-breakpoint-up(md) {
        max-width: calc(min(71.53%, 1030px));
        margin: 0 auto 47px auto;
    }
}

.contents {
    margin: 0 auto;
    max-width: calc(min(89.722%, 330px));
    @include media-breakpoint-up(md) {
        max-width: calc(min(81.53%, 1090px));
    }
}

.header-background-tape {
    @include media-breakpoint-up(md) {
        right: 0;
        bottom: 0;
        width: 12.292%;
        &::after {
            content: '';
            display: table;
            clear: both;
        }
        &::before {
            content: '';
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 172.881%;
        }
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url('../images/international-solutions-background-tape.svg');
        transform: translateY(11.262%);
    }
}
