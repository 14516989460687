@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

// mob: 429 x 281 , 65.5
// d: 600 x 386 , 64.33

.us-warehouse {
    .address1-card-wrapper {
        max-width: 431px;
        overflow: hidden;
        position: relative;
        max-width: 600px;

        &:before {
            content: '';
            display: block;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url('./address-card-mob.svg');
            padding-top: calc(min(550px - 65.5vw));

            @media (min-width: 450px) {
                background-image: url('./address-card.svg');
                padding-top: 69%;
                background-size: contain;
            }
        }

        > .contents {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            margin-top: calc(min(24px, 4vw));
            margin-left: calc(min(44px, 5vw));
            margin-right: calc(min(44px, 5vw));

            @include media-breakpoint-up(sm) {
                margin-top: calc(min(10%, 38px));
                margin-left: calc(min(20%, 76px));
                margin-right: calc(min(20%, 76px));
            }

            .custom-fs-3 {
                font-size: 16px;

                @include media-breakpoint-up(sm) {
                    font-size: 20px;
                }
            }

            .custom-fs-2 {
                font-size: 24px;

                @include media-breakpoint-up(sm) {
                    font-size: 30px;
                }
            }
        }
    }
}
