.green-bg {
    background: #00eb8c;
}

.referral-banner-en {
    background: url('./referral-banner-en.png') no-repeat center;
    background-size: contain;
    background-position: inherit;
    height: 37vw;
    position: relative;
    text-align: start;
    span.refer-friend {
        position: absolute;
        left: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000ff;
    }

    span.join-service {
        position: absolute;
        left: 10%;
        top: 220px;
        font-size: 2vw;
        color: #ffffff;
    }

    a.referral-signup-btn {
        position: absolute;
        left: 10%;
        top: 350px;
    }

    span.color-primary {
        color: #0000ff;
        font-weight: 500;
        font-size: 2.5rem;
    }
}

.referral-banner-ar {
    background: url('./referral-banner-ar.png') no-repeat center;
    background-size: contain;
    background-position: inherit;
    height: 37vw;
    position: relative;
    text-align: start;
    span.refer-friend {
        position: absolute;
        right: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000ff;
    }

    span.join-service {
        position: absolute;
        right: 10%;
        top: 140px;
        font-size: 2vw;
        color: #ffffff;
    }

    a.referral-signup-btn {
        position: absolute;
        right: 10%;
        top: 260px;
    }
}

span.color-primary {
    color: #0000ff;
    font-weight: 500;
    font-size: 2.5rem;
}

div.sec-en-end {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    .sec-desc {
        position: absolute;
        top: 160px;
        text-align: start;
        left: 16%;
        font-weight: 600;
        font-size: 1.5vw;
        width: 30vw;
    }
    .sec-sub-desc {
        position: absolute;
        top: 200px;
        text-align: start;
        left: 16%;
        font-weight: 400;
        font-size: 1.2vw;
        width: 28vw;
    }
    margin: 1rem 0;
}

div.sec-en-start {
    height: auto;
    display: flex;
    padding-left: 10rem;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .sec-desc {
        position: absolute;
        top: 170px;
        text-align: start;
        right: 24%;
        font-weight: 600;
        font-size: 1.5vw;
        width: 26vw;
    }
    .sec-sub-desc {
        position: absolute;
        top: 250px;
        text-align: start;
        right: 25%;
        font-weight: 400;
        font-size: 1.2vw;
        width: 25vw;
    }
    margin: 1rem 0;
}

div.sec-ar-end {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    .sec-desc {
        position: absolute;
        top: 160px;
        text-align: start;
        right: 15%;
        font-weight: 600;
        font-size: 1.4vw;
        width: 25vw;
    }
    .sec-sub-desc {
        position: absolute;
        top: 230px;
        text-align: start;
        right: 15%;
        font-weight: 400;
        font-size: 1.1vw;
        width: 20vw;
    }
    margin: 1rem 0;
}

div.sec-ar-start {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding-right: 10rem;
    .sec-desc {
        position: absolute;
        top: 140px;
        text-align: start;
        left: 20%;
        font-weight: 600;
        font-size: 1.4vw;
        width: 23vw;
    }
    .sec-sub-desc {
        position: absolute;
        top: 180px;
        text-align: start;
        left: 18%;
        font-weight: 400;
        font-size: 1.1vw;
        width: 25vw;
    }

    .sec-sub-desc.top-220 {
        top: 220px !important;
    }

    margin: 1rem 0;
}

.bottom-links {
    font-size: 1.5rem;
}

//max-width: 768px
@media only screen and (max-width: 991px) {
    .referral-banner-en {
        background: url('./referral-banner-en-mobile.png') no-repeat center;
        background-size: cover;
        height: 120vh;
        position: static;
        margin-top: -8px;
        span.refer-friend {
            position: static;
            max-width: 70vw;
            font-size: 10vw;
            padding-top: 2rem;
            margin-left: 3rem;
            color: #0000ff;
        }

        span.join-service {
            margin: 2rem 0 2rem 3rem;
            position: static;
            display: block;
            font-size: 6vw;
            color: #ffffff;
        }
        a.referral-signup-btn {
            position: static;
            margin-left: 3rem;
        }
    }
    .referral-banner-ar {
        background: url('./referral-banner-ar-mobile.png') no-repeat center;
        background-size: cover;
        height: 120vh;
        position: static;
        margin-top: -8px;
        span.refer-friend {
            position: static;
            max-width: 80vw;
            padding-top: 10rem;
            margin-right: 3rem;
            font-size: 5vh;
            margin-bottom: 1rem;
        }

        span.join-service {
            position: static;
            display: block;
            max-width: 60vw;
            margin: 2rem 3rem 2rem 0;
            font-size: 3.5vh;
            color: #ffffff;
            strong {
                display: inline-block;
                // padding: 0 3rem 2rem 0;
            }
        }

        a.referral-signup-btn {
            position: static;
            margin-right: 3rem;
        }
    }

    span.color-primary {
        width: 50%;
        font-size: 2rem;
        margin: 3rem 0;
    }

    div.sec-en-start {
        display: block;
        padding-left: 0;
        .sec-desc {
            top: 0;
            right: 0;
            left: 6.5%;
            margin-left: auto;
            margin-right: auto;
            width: 55vw;
            font-size: 2.5vw;
        }
        .sec-sub-desc {
            top: 25px;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            width: 50vw;
            font-size: 2.2vw;
            text-align: center;
        }
        .sec-img {
            margin-top: 4rem;
            margin-bottom: 0.5rem;
            // width: 100%
        }
    }
    div.sec-en-end {
        display: block;
        .sec-desc {
            position: static;
            width: 50vw;
            font-size: 2.5vw;
        }
        .sec-sub-desc {
            position: static;
            width: 50vw;
            font-size: 2.2vw;
        }
        .sec-img {
            margin-top: 7vh;
            margin-bottom: 2rem;
            // width: 100%
        }
    }
    div.sec-ar-start {
        display: block;
        padding-right: 0;
        .sec-desc {
            right: 27.7%;
            top: 0;
            width: 100vw;
            font-size: 2.5vw;
        }
        .sec-desc.right-32 {
            right: 32.5% !important;
        }
        .sec-sub-desc {
            width: 50vw;
            right: 25%;
            top: 25px;
            font-size: 2.2vw;
            text-align: center;
        }
        .sec-sub-desc.top-220 {
            top: 25px !important;
        }
        .sec-img {
            margin-top: 4rem;
            margin-bottom: 2rem;
            // width: 100%
        }
    }
    div.sec-ar-end {
        display: block;
        padding-right: 0;
        .sec-desc {
            position: static;
            font-size: 2.5vw;
            width: 100vw;
        }
        .sec-sub-desc {
            position: static;
            width: 50vw;
            font-size: 2.2vw;
        }
        .sec-img {
            margin-top: 10vh;
            margin-bottom: 2rem;
            // width: 100%
        }
    }
}
