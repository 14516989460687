.tooltip {
    .tooltip-inner {
        background-color: #243548 !important;
        padding: 8px 16px;
        color: #f4f6f9;
        font-size: 12px;
        font-family: var(--default-font-family);
        html[dir='rtl'] & {
            font-family: var(--default-ar-font-family);
        }
    }

    .arrow {
        &::before {
            border-top-color: #243548 !important;
        }
    }

    &.show {
        opacity: 1 !important;
    }
}

.copy-all {
    font-size: 14px;
    color: #8798a9;
    opacity: 0.67;
}

.copy-all-icon {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    html[dir='rtl'] & {
        margin-right: unset;
        margin-left: 5px;
    }
}
