.calculator-page {
    .calculator-banner-en {
        background: url('./calculator-en.svg') no-repeat;
        background.container {
            position: relative;
            text-align: center;
            color: white;
            background-color: #0000ff;
        }
        span.calculator-warehouse {
            font-weight: bold;
            line-height: 4rem;
        }
        .main-subtitle-banner {
            left: 5.5rem !important;
            top: 12rem !important;
            margin-top: 2rem;
            line-height: 2.5rem;
        }

        .bottom-left {
            position: absolute;
            bottom: 8px;
            left: 16px;
        }

        .top-left {
            position: absolute;
            top: 8px;
            left: 16px;
        }

        .top-right {
            position: absolute;
            top: 8px;
            right: 16px;
        }

        .bottom-right {
            position: absolute;
            bottom: 8px;
            right: 16px;
        }

        .centered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        background-size: cover;
        height: 42vw;
        position: relative;
        text-align: start;
        margin-top: 0 rem;
        span.calculator-warehouse {
            position: absolute;
            left: 6%;
            top: 10%;
            font-size: 3vw;
            color: #0000ff;
        }

        span.sub-text {
            position: absolute;
            left: 5%;
            top: 30%;
            font-size: 2vw;
            color: #000;
        }

        a.signup-btn {
            position: absolute;
            left: 10%;
            top: 57%;
            white-space: nowrap;
        }
    }

    .calculator-banner-ar {
        background: url('./calculator-ar.svg') no-repeat;
        background-size: cover;
        height: 42vw;
        position: relative;
        text-align: start;
        margin-bottom: 0rem;
        margin-top: -0.55rem;

        span.calculator-warehouse {
            position: absolute;
            right: 6%;
            top: 10%;
            font-size: 2.8vw;
            color: #0000ff;
        }

        span.sub-text {
            position: absolute;
            right: 5%;
            top: 38%;
            font-size: 2vw;
            color: #000;
        }

        a.signup-btn {
            position: absolute;
            right: 10%;
            top: 59%;
            white-space: nowrap;
        }
    }

    .w-85 {
        width: 90% !important;
    }

    p.headers {
        font-size: 1.19vw;
        font-weight: 100;
        max-width: 52%;
        margin: 0 auto;
    }

    .font-size-24 {
        font-size: 24px;
    }

    .font-size-20 {
        font-size: 20px;
        line-height: 20px;
    }

    .no-list-style {
        list-style-type: none;
    }
    .span-point-color {
        color: #0000ff;
        font-weight: 500;
    }
    .li-setting-pading {
        padding-left: 0px;
        text-indent: -60px;
        padding-bottom: 15px;
        line-height: 26px;
    }
    .center-div {
        padding: 123px 0;
    }

    //////////////////////@at-root
    .container-img {
        position: relative;
        text-align: justify;
    }

    .bottom-left {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }

    .top-left {
        position: absolute;
        top: 8px;
        left: 16px;
    }

    .top-right {
        position: absolute;
        top: 8px;
        right: 16px;
    }

    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }

    .button-section-add {
        padding: 0px 20px;
    }
    .centered {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .centered-text {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-34%, -36%);
    }

    .centered-text-bg {
        position: absolute;
        top: 40%;
        left: 15%;
        transform: translate(-8%, -36%);
    }

    .calculator-banner-ar span.rtl {
        top: 25%;
    }
    //////////////////////////////@at-root

    .container-width-cal {
        width: 62%;
    }

    .tr-border {
        padding: 20px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .p-m-zero {
        padding: 0px !important;
        margin: 0px;
        background-color: white;
    }

    //max-width: 768px
    @media only screen and (max-width: 768px) {
        .centered {
            position: absolute;
            top: 48%;
            left: 12%;
            transform: translate(-7%, -53%);
        }
        .container-width {
            width: 95%;
        }

        .centered-text {
            position: absolute;
            top: 53%;
            left: -8%;
            transform: translate(5%, -50%);
        }

        .padding-0 {
            padding: 0px !important;
        }

        .font-size-24 {
            font-size: 18px;
        }
        .center-div {
            padding: 0px;
        }
        .calculator-bg-en {
            //background: url(/static/media/steps-en-web.adfb75e1.svg) no-repeat;
            background-position: top;
            background-size: 214%;
            background-position-x: -213px;
        }
        .li-setting-pading {
            text-indent: -40px;
            padding-bottom: 25px;
            line-height: 20px;
        }
        .calculater {
            padding: 25px 0px !important;
        }
        .calculator-banner-en {
            background: url('./calculator-en-mobile.svg') no-repeat center;
            background-size: cover;
            height: 100vh;
            margin-top: 0;
            position: center;
            margin-bottom: 1rem;
            span.calculator-warehouse {
                position: static;
                max-width: 73vw;
                font-size: 8vw;
                padding-top: 2rem;
                margin-left: 1.5rem;
                color: #0000ff;
            }

            span.sub-text {
                margin: 1.5rem 0 2rem 1.5rem;
                position: static;
                display: block;
                font-size: 6vw;
            }
            a.signup-btn {
                position: static;
                margin-left: 3rem;
            }
        }
        .calculator-banner-ar {
            background: url('./calculator-ar-mobile.svg') no-repeat center;
            background-size: cover;
            height: 100vh;
            position: static;
            margin-bottom: 1rem;
            padding: 0 2rem;
            margin-top: 0;
            span.calculator-warehouse {
                position: static;
                max-width: 80vw;
                padding-top: 2rem;
                // margin-right: 3rem;
                font-size: 4.5vh;
                margin-bottom: 1rem;
            }

            span.sub-text {
                position: static;
                // margin: 2rem 3rem 2rem 0;
                max-width: 60vw;
                font-size: 2.5vh;
                margin-top: 2rem;
                strong {
                    display: block;
                    padding: 0 3rem 2rem 0;
                }
            }

            a.signup-btn {
                position: static;
                display: block !important;
                width: fit-content !important;
                margin: 2rem 0;
            }
        }

        p.headers {
            font-size: 5.3vw;
            font-weight: 100;
            max-width: 75vw;
            margin: 0 auto;
        }
    }
}
