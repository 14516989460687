@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.header {
    background-color: #ffffff;
    position: relative;
}

.header-background {
    @include media-breakpoint-up(lg) {
        &::after {
            content: '';
            display: table;
            clear: both;
        }
        &::before {
            content: '';
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 32.984%; // divided by two
        }
        background-repeat: no-repeat;
        background-position: right;
        background-size: auto 100%;
        background-image: url('../images/header-background-image.png');
    }
}

.header-background-tape {
    @include media-breakpoint-up(lg) {
        right: 0;
        bottom: -5.1%;
        width: 15.9%;
        &::after {
            content: '';
            display: table;
            clear: both;
        }
        &::before {
            content: '';
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 161.14%;
        }
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('../images/header-background-image-tape.svg');
    }
}

.header-bar {
    padding: calc(min(12px, 3.334%)) calc(min(21px, 5.834%)) calc(min(30px, 8.334%)) calc(min(21px, 5.834%));
    @include media-breakpoint-up(sm) {
        padding: calc(min(23px, 1.6%)) calc(min(42px, 2.92%)) calc(min(104px, 7.222%)) calc(min(42px, 2.92%));
    }
}

.header-content {
    max-width: 316px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
        max-width: unset;
        margin: 0 0 calc(min(104px, 8.333%)) calc(min(6.95%, 100px));
    }
}

.mobile-image {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 65.181%;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../images/header-mobile-background-image.svg');
}

.mobile-image-tape {
    right: 0;
    bottom: -3.8%;
    width: 23.06%;
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 174.2%;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('../images/header-mobile-background-image-tape.svg');
}
