@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.directory-page {
    > .how-it-works {
        max-width: 1177px;
        margin: 0 auto;

        .title {
            text-align: center;
            font-weight: 600;
            font-size: 30px;
            color: #243548;
            margin-bottom: 21px;
            @include media-breakpoint-up(sm) {
                font-size: 34px;
                margin-bottom: 29px;
            }
        }

        .tab-bar {
            display: inline-block;
            background-color: #e4e8f1;
            border-radius: 60px;
            color: #243548;
            font-weight: 500;
            font-size: 14px;
            padding: 5px 7px;
            margin-bottom: 32px;
            @include media-breakpoint-up(sm) {
                font-size: 18px;
                padding: 7px 10px;
                margin-bottom: 69px;
            }

            > a {
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: bottom; // fix extra space cause by hidden overflow
                border-radius: 72px;
                transition:
                    color 300ms,
                    background-color 300ms;
                padding: 9px 23px;
                @include media-breakpoint-up(sm) {
                    padding: 15px 34px;
                }
                &:hover {
                    color: #0000ff;
                }
                &.active {
                    background-color: #0000ff;
                    color: white;
                }
            }
        }

        .tab-content {
            text-align: center;
            .active {
                background-color: unset;
            }
        }
    }
}
