.icon {
    width: 238px;
}

.go-home {
    padding-left: 79px;
    padding-right: 79px;
    font-weight: 500;
    color: #f4f6f9;
    max-width: 242px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.thank-you {
    font-weight: 500;
    font-size: 26px;
    color: #243548;
}

.elements {
    max-width: 1044px;
    width: 72.5%;
    margin: 0 auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    @media (max-width: 530px) {
        width: 100%;
        border-radius: none;
        box-shadow: none;
    }
}

.container {
    background-color: #f4f6f9;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-height: 610px) {
        height: calc(100vh - 160px);
    }
}
