.calculator {
    @media (min-width: 1200px) {
        flex: 0 0 57.57%;
        max-width: 57.57%;
    }
}

.box {
    @media (min-width: 1200px) {
        flex: 0 0 21.15%;
        flex: 0 0 21.15%;
    }
}

.trueWeight {
    @media (min-width: 1200px) {
        flex: 0 0 21.15%;
        max-width: 21.15%;
    }
}

.custom-modal-lg {
    @media (min-width: 992px) {
        max-width: 1180px;
    }
}

.px-3-5 {
    padding: 0 18px;
}
