.happy-halloween-container {
    width: 100%;
    padding: 16rem 7rem 0;
    height: 120vh;
}

.halloween-background {
    //1818 × 2348 px
    background: url('./background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    height: 100%;
    width: 100%;
    padding: 0 0 10rem;
}

.happy-halloween {
    font-size: 5rem;
    max-width: 60%;
}

.text-right h1 {
    letter-spacing: 2;
    font-family: 'DIN Next LT Arabic';
    padding-top: 7.5rem;
    font-size: 6rem;
}

@media only screen and (min-width: 680px) and (max-width: 1024px) {
    .happy-halloween-container {
        height: 100vh;
        padding: 1rem 2rem;
    }

    .happy-halloween {
        font-size: 3.5rem;
        max-width: 100%;
    }

    .halloween-background {
        height: auto;
        background-position: center;
        padding: 0;
    }

    .halloween-row {
        padding-inline: 1rem;
    }

    .brand {
        width: 100%;
    }

    .text-right h1 {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 513px) and (max-width: 679px) {
    .happy-halloween-container {
        height: 60vh;
        padding: 2rem 2rem;
    }

    .happy-halloween {
        font-size: 3rem;
        max-width: 90%;
    }

    .halloween-background {
        height: auto;
        background-position: center;
        padding: 0 0 1rem;
    }

    .halloween-row {
        padding-inline: 1.3rem;
    }

    .col-4 {
        padding-inline: 0.3rem;
    }

    .brand {
        width: 100%;
    }

    .text-right h1 {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 319px) and (max-width: 512px) {
    .happy-halloween-container {
        height: 40vh;
        padding: 1rem 2rem;
    }

    .happy-halloween {
        font-size: 2rem;
        max-width: 90%;
    }

    .halloween-background {
        height: auto;
        background-position: center;
        padding: 0 0 1rem;
    }

    .halloween-row {
        padding-inline: 1.3rem;
    }

    .col-4 {
        padding-inline: 0.3rem;
    }

    .brand {
        width: 100%;
    }

    .text-right h1 {
        font-size: 2.5rem;
    }
}
