@import '../../constants/constants.css';
@import '~bootstrap/scss/bootstrap.scss';

.container {
    min-width: 53%;
    max-width: 55%;
    &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1920px) {
    .container {
        min-width: 60%;
        max-width: 62%;
        &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .custom-container {
        min-width: 70%;
        max-width: 72%;
    }
}

@media only screen and (max-width: 1440px) {
    .container {
        min-width: 76%;
        max-width: 80%;
        &.loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .custom-container {
        min-width: 86%;
        max-width: 90%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: var(--font-weight-600);
}

.auto-min-height {
    min-height: auto !important;
    height: auto !important;
}

.auto-min-width {
    min-width: auto !important;
}

a {
    color: #0000ff;
    text-decoration: none;
}

.blue-color {
    color: #0000ff !important;
}

.white-color {
    color: #ffffff;
}

.width-90-sections {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
}

.flex-basis-starting-container {
    flex: 1 1 0px;
    margin-left: 20px;
    margin-right: 20px;
}

.global-section-container {
    width: 100%;
    padding: 0;
    text-align: center;
}

.main-section-container {
    align-items: center;
    padding: 25px 0px 25px 0px;

    #myTabContentDifferentiator {
        .active {
            background-color: transparent;
        }
    }
}

.grey-background {
    background-color: var(--grey-background-section-color) !important;
}

.white-background {
    background-color: var(--white-background-color) !important;
}

.grey-border {
    border: 1px solid var(--grey-background-section-color);
}

.border-radius-10 {
    border-radius: 10px;
}

.main-section-row {
    align-items: center;
    justify-content: center;
}

.user-dropdown {
    //to be solved, quick solution
    width: auto !important;
    left: auto !important;
    margin-right: 5%;
}

.dropdown-container {
    position: relative;
}

label,
.label {
    font-weight: 600 !important;
}

.title {
    font-size: var(--font-size-main-title-44);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-main-title-53);
}

.description {
    font-size: var(--font-size-section-button-18);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-main-description-29);
}

.feature-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #66727f;
}

.plans-features-section > div {
    padding: 20px;
}

.display-over-md {
    display: inline-block;
}

.display-under-md {
    display: none;
}

.text-green {
    color: var(--green) !important;
}

.google-bg {
    background-color: #4285f4;
}

.fb-bg {
    background-color: #3b5998;
}

.text-grey {
    color: var(--text-grey);
}

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 0 1rem;
}

.display-none-over-768 {
    display: none;
}

.btn,
.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
    font-size: var(--font-size-section-button-18);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-button-27);
    display: inline-block;
    font-weight: 500;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: var(--default-border-radius);
    text-align: center;
    min-width: 200px;
    height: 48px;
    transition: none;
}

.shopini-links > a {
    margin: 2px 0 2px 0 !important;
}

.shadow-2 {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
}

div.collasible-nav-dropdown {
    position: relative !important;
}

div.collasible-nav-dropdown > div {
    padding-bottom: 0;
    padding-top: 12px;
    width: max-content !important;
}

.shopini-links > a:hover,
.shopini-links > a:focus,
.shopini-links > a:active,
.shopini-links > a:visited {
    font-size: var(--default-font-size-16) !important;
    font-weight: var(--font-weight-500) !important;
    line-height: var(--line-height-description-26) !important;
    color: var(--green-on-hover);
    background-color: var(--white-background-color);
    display: inline-block;
    font-weight: 500;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: var(--default-border-radius);
    text-align: center;
    min-width: 200px;
    transition: none;
    text-decoration: none;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    background-color: transparent;
}

.btn-primary,
#btn-primary {
    color: var(--white-background-color) !important;
    background-color: var(--button-text-blue) !important;
    border-color: var(--white-background-color) !important;
    white-space: nowrap;
    &:hover {
        opacity: 0.6 !important;
    }
}

.btn-info,
.btn-info:hover,
.btn-info:active {
    min-width: auto;
    color: var(--white-background-color);
    background-color: var(--button-text-blue);
    border-color: var(--white-background-color);
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    min-width: 35px;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    color: #0000ff;
}

.btn-no {
    color: #ee2e24 !important;
    cursor: pointer !important;
    background: rgba(238, 46, 36, 0.1) !important;
    box-shadow: 0px 5px 10px rgba(89, 89, 89, 0.1);
    border-radius: 6px;
}

.btn-cancel {
    color: #ee2e24 !important;
    cursor: pointer !important;
    background: rgba(238, 46, 36, 0.1) !important;
    box-shadow: 0px 5px 10px rgba(89, 89, 89, 0.1);
    border-radius: 6px;
}

.font-18,
.faq-link {
    font-size: var(--font-size-section-button-18);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-button-27);
}

.font-12 {
    font-size: var(--font-size-12) !important;
}

.font-11 {
    font-size: 11px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: var(--font-size-14) !important;
}

.font-16-400 {
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-description-26);
}

.font-16-500 {
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-description-26);
}

.width-100 {
    width: 100%;
}

.en {
    font-family: var(--default-font-family);
}

.ar {
    font-family: var(--default-ar-font-family);
}

body {
    color: var(--default-font-color);
}

.text-black {
    color: var(--default-font-color);
}

.shop-ship-section-container {
    padding: 40px 0px 40px 0px;
}

.services-section-container {
    background-color: var(--grey-background-section-color);
    padding: 40px 0px 40px 0px;
}

.footer-links-container {
    padding: 45px 45px 15px 45px;
    background: #0000ff;
    color: #00eb8c;
    p.mx-w-20 {
        max-width: 9.5rem;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-col > div {
    flex: 1;
}

.footer-row {
    display: flex;
    flex-direction: row;
}

.footer-row a {
    margin-left: 3px !important;
    margin-right: 3px !important;
    text-decoration: none;
}

.cards-container {
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
}

.footer-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    padding: 0 45px;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.copyright-div {
    height: 60px;
    background-color: var(--grey-background-section-color);
}

#premium-plan-tab,
#basic-plan-tab,
#business-plan-tab {
    text-align: center;
    color: #8798a9;
}

.copyright-div p {
    margin: 0;
    opacity: 0.8;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.services-cards-container {
    display: flex;
    flex-direction: row;
    background-color: var(--grey-background-section-color);
    padding-left: 0px;
    padding-right: 0px;
}

.services-section-container .card {
    background-color: var(--grey-background-section-color) !important;
}

Card.Img {
    max-width: 283.09px !important;
}

#see-how-it-works-button,
.color-inverse-default-button {
    color: var(--button-text-blue) !important;
    border: 1px solid !important;
    border-color: var(--button-text-blue) !important;
    background-color: var(--white-background-color) !important;
    &:hover {
        color: #fff !important;
        background: #0000ff !important;
        transition: 0.5s;
        opacity: 1 !important;
    }
}

.transparent-button {
    color: var(--button-text-blue) !important;
    border: 1px solid !important;
    border-color: var(--button-text-blue) !important;
    background: transparent !important;
    &:hover {
        color: #fff !important;
        background: #0000ff !important;
        transition: 0.5s;
        opacity: 1 !important;
    }
}

.light-blue-button {
    color: var(--button-text-blue) !important;
    border: 0 !important;
    background: rgba(0, 0, 255, 0.1) !important;
    &:hover {
        color: #fff !important;
        background: #0000ff !important;
        transition: 0.5s;
        opacity: 1 !important;
    }
}

#get-tax-free-address-button {
    color: var(--button-text-blue);
    border: 1px solid !important;
    border-color: var(--button-text-blue) !important;
    background-color: var(--grey-background-section-color);
}

Card {
    margin: 0px 30px 0px 30px;
}

Card.Title {
    font-size: var(--font-size-card-title-22);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-card-title-33);
}

Card.Text {
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-description-26);
}

.card {
    border: none;
    align-items: center;
}

.big-section-img {
    max-width: 520px;
}

.section-img {
    max-width: 283.09px !important;
    align-self: center;
}

.tiny-section-img {
    max-width: 70px !important;
    max-height: 70px !important;
}

.tiny-section-img-60 {
    width: 60px;
    height: 35px;
    display: grid;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.section-title {
    font-size: var(--font-size-section-title-34);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-section-title-51);
}

.sub-section-title {
    font-size: var(--font-size-card-title-22);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-card-title-33);
}

.small-img-carousel {
    display: flex;
    //   height: 75px !important;
    //   width: 180px !important;
    //   align-self: center;
    align-items: center;
    justify-content: center;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.carousel-indicators li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--button-text-blue);
}

.carousel-indicators {
    bottom: -35px;
    padding: 0px;
}

.calculate-form {
    background-color: var(--grey-background-section-color);
    border-radius: 10px;
    padding: 24px 24px 24px 24px;
}

.table-responsive-md {
    padding: 30px 20px 0 20px;
}

.required:after {
    content: '*';
    color: #ee2e24;
    margin: 0 5px;
    font-weight: bold;
}

.accordion-arrow {
    width: 12px;
    margin-left: 7px;
    margin-right: 7px;
    padding-bottom: 2px;
}

.left-right-arrow {
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 2px;
}

.ltr {
    text-align: left !important;
    align-items: left !important;
}

.absolute-ltr {
    text-align: left !important;
    align-items: left !important;
}

.ltr-direction {
    direction: ltr;
}

.rtl {
    text-align: right !important;
    align-items: right !important;
}

.absolute-rtl {
    text-align: right !important;
    align-items: right !important;
}

.width-float-end {
    width: 100%;
    text-align: end;
    justify-content: flex-end;
}

.navbar-collapse {
    flex-grow: unset;
}

.navbar-toggler {
    border: none !important;
}

.navbar-brand {
    display: flex;
    flex-grow: 1;
    text-align: start;
    padding: 0px 12px 0px 12px;
}

.margin-60-neg {
    margin-top: -60px !important;
}

.margin-45-neg {
    margin-top: -45px !important;
}

.div-link {
    padding: 0 15px 0 15px;
}

.text-start {
    text-align: start;
}

.text-end {
    text-align: end;
}

.country-list > li {
    text-align: left;
}

.lower-z-2 {
    z-index: -2;
}

.full-features-link:hover {
    color: var(--green-on-hover) !important;
}

.footer-link,
.footer-link:active,
.footer-link:focus {
    background-color: var(--white-background-color);
    color: #243548 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.footer-link:hover {
    color: var(--green-on-hover) !important;
    background-color: var(--white-background-color) !important;
}

.footer-links-custom-container {
    padding-bottom: 5rem;
    .margin-ar {
        margin-left: 1.3vw;
    }
    .margin-en {
        margin-right: 1.3vw;
    }
}

@media (min-width: 768px) {
    .footer-links-custom-container {
        .mr-8 {
            margin-right: 8vw;
        }
    }
}

// .custom-col-1 {
//     flex: 0 0 0.33333%;
//     max-width: 0.33333%;
// }

.visa-footer-en {
    padding-top: 4.6rem;
}

.visa-footer-ar {
    padding-top: 4rem;
}

.close-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.25em;
    vertical-align: middle;
    content: '';
    background: 50% / 100% 100% no-repeat;
}

input,
select {
    height: 44px !important;
}

input:invalid.rate-calculator-input-validation {
    border: 1px dashed rgb(199, 67, 67);
}

select:invalid.rate-calculator-input-validation {
    border: 1px dashed rgb(199, 67, 67);
}

input[type='number']::-webkit-inner-spin-button {
    display: none;
}

input[type='number']::-moz-inner-spin-button {
    display: none;
}

.calculation-table-scrollbar {
    position: relative;
    height: 450px;
    width: 580px;
    overflow: auto;
    margin-top: 50px;
    -webkit-overflow-scrolling: auto;
}

.calculation-table-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    display: block;
}

.calculation-table-scrollbar::-webkit-scrollbar:vertical {
    width: 7px;
}

.calculation-table-scrollbar::-webkit-scrollbar:horizontal {
    height: 7px;
}

.calculation-table-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.calculation-table-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
}

.table-wrapper-scroll-y {
    display: block;
}

.table td,
.table th {
    border: none;
    height: 60px;
    vertical-align: middle;
    text-align: start;
}

.table tbody tr td .flex-row p {
    margin: 0 15px 0 15px;
}

.container-card {
    padding-right: 0px;
    padding-left: 0px;
}

.card-text {
    padding-bottom: 19px;
}

.dropdown {
    position: static !important;
}

.dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin-top: 0px !important;
    width: 100% !important;
}

#user-dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem;
}

.user-dropdown {
    width: auto !important;
}

.table td,
.table th {
    border-top: none;
}

.popular-highlighter-en {
    background-color: #22d180 !important;
    border-radius: 100px;
    color: white;
    padding: 5px 0px 5px 0px;
    width: 30%;
    position: absolute;
    top: -3%;
    left: 35%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
}

.popular-highlighter-ar {
    background-color: #22d180 !important;
    border-radius: 100px;
    color: white;
    padding: 5px 0px 5px 0px;
    width: 30%;
    position: absolute;
    top: -3%;
    right: 35%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
}

.inner-dropdown {
    font-weight: var(--font-weight-400) !important;
}

.form-row > .col {
    text-align: start;
}

.advanced-calculater {
    width: 100%;
    text-align: start !important;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 12px;
    color: '#0a20e1';
}

.sub-page-header {
    font-size: var(--font-size-32);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-48);
}

.sub-page-section-header,
h2 {
    font-size: var(--font-size-26);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-39);
}

.rotate-180 {
    transform: rotate(180deg);
}

.step-color-orange {
    color: var(--step-text-color);
}

.post-450-start-text-align {
    text-align: start !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    padding-left: 5px !important;
    padding-right: 2px !important;
}

.separater-bottom-grey {
    border-bottom: 2px solid #f4f6f9;
}

.contact-us-form {
    padding: 2em 2.5em 2em 2.5em;
}

.intl-tel-input {
    display: block;
    width: 100%;
}

.nav-container {
    width: 100%;
    box-shadow: var(--navigation-box-shadow);
    max-height: 75px;
}

.nav-pills > a,
.accordion-question-card {
    border-bottom: 1px solid #e4e8f1 !important;
}

.card.accordion-question-card {
    border: none;
}

.accordion-question-card a {
    flex-wrap: nowrap;
}

.accordion-question-card a img {
    align-self: flex-start;
    padding-top: 10px;
}

.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
    display: none;
}

.navbar-toggler:not(.collapsed) > .close {
    display: inline;
}

.shopini-icon {
    width: var(--shopini-icon-width);
}

.nav-right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-right-buttons > Button {
    min-width: var(--navigation-button-width) !important;
    height: var(--navigation-button-height);
    background-color: var(--white-background-color);
    border: var(--border-none) !important;
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-500);
    color: var(--default-font-color);
}

.nav-right-buttons > Button:focus {
    border: var(--border-none) !important;
    outline: 0 !important;
}

.greenish-button,
input[type='file']::-webkit-file-upload-button,
.faq-link:active,
.nav-link.active.faq-link {
    background-color: var(--green) !important;
    border: var(--border-none);
    color: var(--white-background-color);
    display: inline-block;
    box-shadow: var(--default-box-shadow);
    border-radius: var(--default-border-radius);
    line-height: var(--line-height-navigation-button-24);
}

#sign-up-button {
    border: var(--border-none);
    color: var(--white-background-color);
    display: inline-block;
    box-shadow: var(--default-box-shadow);
    border-radius: var(--default-border-radius);
    line-height: var(--line-height-navigation-button-24);
}

.faq-link:active,
.nav-link.active {
    text-align: start;
}

.faq-link,
.faq-link:hover,
.nav-link.active {
    color: var(--default-font-color);
    text-align: start;
}

.badge {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    font-size: 100%;
}

.bx {
    vertical-align: middle;
}

.faq-link.active .badge {
    height: 32px;
    width: 32px;
    background: #ffffff;
    color: #00eb8c;
    border-radius: 50%;
}

.faq-link:not(.active) .badge,
.rounded-green {
    color: #ffffff;
    background: #00eb8c;
    border-radius: 50%;
}

#sign-up-button {
    font-size: var(--default-font-size-16) !important;
    height: 38px;
}

#sign-in-button,
#language-button {
    line-height: var(--line-height-navigation-button-24);
    height: auto;
    background: white !important;
    border: none;
    color: black !important;
}

#sign-in-button {
    margin: 0 1rem !important;
    border: 1px solid #0000ff;
    border-radius: 5.636px;
    color: #0000ff !important;
    padding-inline: 1.5rem;
    &:hover {
        color: #fff !important;
        background: #0000ff !important;
        transition: 0.5s;
        font-size: var(--default-font-size-16) !important;
    }
}

#sign-up-button:hover {
    font-size: var(--default-font-size-16) !important;
    opacity: 0.5;
    transition: 0.5s;
}

// #sign-in-button:focus,
#language-button:hover,
// #language-button:focus,
.interactive-button {
    font-size: var(--default-font-size-16) !important;
    color: var(--green-on-hover) !important;
    background-color: var(--white-background-color);
    transition-duration: 0.25s;
}

.grey-tooltip + .tooltip > .tooltip-inner {
    background-color: #0a20e1;
}

#responsive-navbar-nav .navbar-collapse.collapse {
    align-items: right;
}

#navbar-dropdown-toggle {
    position: absolute;
}

.nav-buttons-font,
.nav-buttons-font:hover,
.nav-buttons-font:active,
.header-main-menu-text {
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-500);
    color: var(--default-font-color) !important;
    text-decoration: none;
}

.nav-buttons-font:not(.collapsed) > img {
    transform: rotate(180deg);
    margin-top: 7px;
    transition-duration: 0.5s;
}

.menu-dropdown.show .menu-dropdown-arrow {
    transform: rotate(180deg);
    transition-duration: 0.5s;
}

.menu-dropdown:not(.show) .menu-dropdown-arrow {
    transform: rotate(0deg);
    transition-duration: 0.5s;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu.user-dropdown {
    text-align: start;
    border: 1px solid var(--grey-background-section-color);
    box-shadow: none !important;
    padding: 0px;
    transform: translate3d(0px, 46px, 0px);
    top: 0;
}

.dropdown-item.active {
    font-size: var(--default-font-size-16);
    font-weight: var(--font-weight-500);
    color: var(--default-font-color) !important;
    background-color: white;
}

.info-page {
    img {
        margin: 2rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    h2 {
        margin-bottom: 2rem;
    }
}

.info-page,
.login-modal-width {
    min-width: 646px;
}

.forget-password-width {
    min-width: 550px;
}

.w-50-mobile-100 {
    width: 50%;
    padding: 2.5em;
}

.w-25-mobile-100 {
    width: 25%;
    padding: 2em;
}

#shipping-fees-button,
.cancel,
.cancel:hover {
    color: var(--button-text-blue) !important;
    border: 1px solid !important;
    border-color: var(--button-text-blue) !important;
    background-color: var(--grey-background-section-color) !important;
}

.dashboard-nav-items-link a {
    color: black;
    font-weight: 500;
}

.container-width {
    width: 75%;
}

.mb-show-block {
    display: none !important;
}

a.primary-address a.primary-address:hover {
    color: #fff;
}

.word-spacing {
    word-spacing: 0.3rem !important;
}

.light-red-box {
    background: rgba(255, 143, 107, 0.25);
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    max-height: 150px !important;
}

.password-field .MuiOutlinedInput-input {
    line-height: 0 !important;
    padding: 0.2rem 0.5rem !important;
}

.password-field .MuiInputBase-root {
    width: 100% !important;
}

a.wallet-btn {
    background: rgba(34, 209, 128, 0.11);
    border: none;
    border-radius: 6px;
    color: #000000;
}

.white-space-nowrap {
    white-space: nowrap;
}

a.wallet-btn {
	background: rgba(34, 209, 128, 0.11);
	border: none;
	border-radius: 6px;
	color: #000000;
}

.white-space-nowrap {
	white-space: nowrap;
}

@media only screen and (max-width: 1050px) {
    .btn,
    .btn:hover,
    .btn:focus,
    .btn:active,
    .btn:visited {
        min-width: 120px;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .container-width {
        width: 100%;
    }

    .mb-hide {
        display: none !important;
    }

    .w-50-mobile-100,
    .w-25-mobile-100 {
        width: 100%;
        padding: 1.5em;
    }

    .info-page,
    .login-modal-width,
    .forget-password-width {
        min-width: auto;
    }

    .mb-show-block {
        display: block !important;
    }

    .mobile-width-50-percent {
        width: 50% !important;
    }

    .tab-content > .active {
        display: block;
    }

    .nav-div {
        flex-wrap: wrap;
    }

    .display-none-over-768 {
        display: inline;
    }

    .mb-white-background {
        background-color: var(--white-background-color) !important;
    }

    .display-over-md {
        display: none;
    }

    .display-under-md {
        display: inline-block;
    }

    .mb-footer {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .cards-container,
    .services-cards-container {
        flex-wrap: wrap;
    }

    .width-100-sections {
        width: 100%;
    }

    .faq-link {
        height: auto;
    }
    .navbar-collapse {
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        bottom: 0;
    }

    .calculate-form,
    .calculation-table-scrollbar {
        width: auto;
        flex-wrap: wrap;
    }

    .ltr {
        text-align: center !important;
        align-items: center !important;
    }

    .rtl {
        text-align: center !important;
        align-items: center !important;
    }

    .footer-row {
        justify-content: center;
    }

    //   .small-img-carousel {
    //     max-width: 90px;
    //   }

    .section-title {
        font-size: var(--font-size-card-title-22);
        line-height: var(--line-height-card-title-33);
    }

    .tableDisplay {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .justify-content-around-under-768 {
        justify-content: space-around !important;
    }

    .contact-us-form {
        padding: 2em 1.5em 2em 1.5em;
    }

    .addresses-container {
        padding: 0 !important;
    }

    .mr-nav-auto {
        position: relative;
        display: flex;
        z-index: 20;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start !important;
        background-color: white;
        width: 100%;
        padding: 20px 20px 20px 20px;
        min-height: 100vh;
    }

    .menu-dropdown a {
        margin-bottom: 8px;
    }

    .plan-link.active {
        color: #495057 !important;
        background-color: #fff !important;
        border-color: #dee2e6 #dee2e6 #fff;
    }

    .feature-link {
        text-align: center !important;
        color: #495057;
    }

    .feature-link.active {
        color: black !important;
        background-color: var(--grey-background-section-color) !important;
        border-color: #dee2e6 #dee2e6 #fff;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .displayed-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .modal-content {
        padding-top: 2rem !important;
    }

    .calculate-form,
    .calculation-table-scrollbar {
        width: 95%;
        flex-wrap: wrap;
    }

    .btn {
        width: 100%;
        height: auto;
        text-align: center !important;
    }

    .advanced-calculater {
        text-align: start !important;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .form-row input,
    .form-row select {
        width: 100%;
    }

    .advancedCalculatorStyleForMobile {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .card-horizontal {
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
    }

    .pre-450-centered-text {
        text-align: center !important;
    }

    .center-align-last-line {
        text-align-last: center;
    }

    .contact-us-form {
        padding: 2em 1em 2em 1em;
        width: 95%;
    }

    .en {
        .left-illustrator {
            width: 137px;
            left: -15px;
            top: 90px;
        }
    }
    .ar {
        .left-illustrator {
            width: 137px;
            left: -27px;
            top: 90px;
        }
    }

    .right-referral-illustrator {
        top: -78px !important;
        width: 100px;
        min-width: 100px !important;
    }

    .left-referral-illustrator {
        top: 110px !important;
        width: 100px;
        min-width: 100px !important;
    }

    .dashboard-container {
        .right-illustrator {
            margin-right: 0px !important;
            margin-left: 0px !important;
            right: -18px !important;
            width: 110px;
            top: 90px;
        }

        .font-2 {
            font-size: 2.7vw;
        }

        .submit-request-button {
            font-size: 13px;
            min-width: 100px;
            width: 110px;
            height: 10px !important;
            padding: 0px !important;
            min-height: 30px !important;
        }
        .buy_heading {
            font-size: 16px !important;
            margin-top: 0px !important;
        }
        .buy_description {
            font-size: 11px !important;
        }
        button {
            &.close-btn-position {
                top: 20px;
                .font-40 {
                    font-size: 22px !important;
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 501px) {
    .dashboard-container {
        .right-illustrator {
            margin-right: 0px !important;
            margin-left: 0px !important;
            right: -5px !important;
            width: 130px;
            top: 65px;
        }
        .left-illustrator {
            width: 160px;
            left: -1px;
            top: 65px;
        }

        img.right-referral-illustrator {
            width: 140px !important;
            min-width: 140px !important;
            top: -115px;
        }

        img.left-referral-illustrator {
            width: 140px !important;
            min-width: 140px !important;
            top: 75px;
        }

        .submit-request-button {
            font-size: 13px;
            min-width: 100px;
            width: 110px;
            height: 10px !important;
            padding: 0px !important;
            min-height: 30px !important;
        }
        .buy_heading {
            font-size: 16px !important;
            margin-top: 0px !important;
        }
        .buy_description {
            font-size: 11px !important;
        }
        button {
            &.close-btn-position {
                top: 20px;
                .font-40 {
                    font-size: 22px !important;
                }
            }
        }
    }
}

/* No greater than 768px, no less than 1530px */
// @media (min-width:767px) and (max-width:1530px) {
//   .accordion-row {
//     max-width: 78%;
//     margin-left: 30px;
//   }
// }

.bg-light-blue {
    background: #deebff;
    height: auto;
    padding: 1rem;
}

.bg-light-grey {
    background: #e5e5e5;
    height: auto;
    padding: 2.5rem 2rem 3rem;
}

.map-text {
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
    padding: 1.7rem 0.2rem;
    &.margin-en {
        margin-left: 0.7rem;
    }
    &.margin-ar {
        margin-right: 0.7rem;
    }
}

.map-text:hover {
    background: #fff !important;
    color: #22d180;
}

.text-width-17 {
    width: 17rem;
}

.custom-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    min-width: 70%;
    max-width: 72%;
}

.landing-links:hover {
    color: #00eb8c;
    text-decoration: none;
}

@media (max-width: 768px) {
    .map-text {
        left: 20%;
    }
}

.error {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

@media (max-width: 425px) {
    .paypal {
        img {
            width: 100%;
        }
    }
}

.new-card-container {
    .paypal-button {
        .spinner {
            left: 11em !important;
            top: 1.4em !important;
        }
    }
}
.paypal-button {
    .paypal-deactive {
        opacity: 0.6;
        filter: grayscale;
    }

    .spinner {
        left: 7em;
        top: 1.25em;
        width: 2.5em;
        display: block;
        position: absolute;
    }

    /* spinner animation */
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    /* The actual spinner element is a pseudo-element */
    .spinner::before {
        content: '';
        width: 1.5em; /* Size of the spinner */
        height: 1.5em; /* Change as desired */
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        border: solid 0.35em #999; /* Thickness/color of spinner track */
        border-bottom-color: #555; /* Color of variant spinner piece */
        animation: 0.8s linear infinite spinner; /* speed of spinner */
        transform: translate(-50%, -50%);
        will-change: transform;
    }
}

.font-2 {
    font-size: 2.5vw !important;
}

.dark-bg {
    background: rgba(0, 0, 0, 0.7);
}

#shipping-service-change {
    line-height: 30px;
}

.fs-1 {
    font-size: 26px !important;
    @include media-breakpoint-up(sm) {
        font-size: 32px !important;
    }
}

.fs-2 {
    font-size: 22px !important;
    @include media-breakpoint-up(sm) {
        font-size: 26px !important;
    }
}

.fs-3 {
    font-size: 20px !important;
    @include media-breakpoint-up(sm) {
        font-size: 24px !important;
    }
}

.fs-4 {
    font-size: 18px !important;
}

.fs-5 {
    font-size: 16px !important;
}

.fs-10px {
    font-size: 10px !important;
}

.bg-color-light-grey {
    background-color: #f4f6f9;
}

.text-blue-color {
    color: #0000ff;
}

.bg-grey-6 {
    background-color: #eff5ff;
}

.bg-grey-1-alpha {
    background-color: rgba(34, 209, 128, 0.07);
}

.fs-34px {
    font-size: 34px !important;
}

.fs-31px {
    font-size: 31px !important;
}

.fs-28px {
    font-size: 28px !important;
}

.fs-26px {
    font-size: 26px !important;
}

.fs-22px {
    font-size: 22px !important;
}

.fs-20px {
    font-size: 20px !important;
}

.fs-14px {
    font-size: 14px !important;
}

.fs-13px {
    font-size: 13px !important;
}

.fs-12px {
    font-size: 12px !important;
}

.fs-10px {
    font-size: 10px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.orange-badge {
    background-color: #ff8f6b !important;
}

.yellow-badge {
    background-color: #e9ef1d !important;
}

.red-badge {
    background-color: #ee2e24 !important;
}

.text-black {
    color: #000000 !important;
}
