#promotionHome {
    margin-top: 20px;
}

.promotion-price {
    font-weight: 500 !important;
    font-size: 38px !important;
    color: #0a20e1 !important;
}

.green-badge {
    background-color: #22d180 !important;
}

.red {
    color: red;
}

.font-16 {
    font-size: var(--default-font-size-16);
    line-height: var(--line-height-description-26);
}

.card-shadow {
    text-align: center;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
}

p.d-inline > div {
    display: inline;
}

.min-height-600 {
    min-height: 37.5rem;
}

.min-height-424 {
    min-height: 26.5rem;
}

.min-height-72 {
    min-width: 4.5rem;
}

// .width-350 {
//   width: 21.875rem;
// }

.promotion {
    text-align: start;
}

.promotion > hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.promotion > i {
    width: 20%;
    text-align: end;
}

.promotion > p {
    width: 80%;
    height: 100%;
    text-align: center;
}

.amchamFreeMembership {
    position: absolute;
    background-color: #d33d42;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    width: 97%;
    left: 10px;
}

.amchamFreeMembership > p {
    color: white !important;
}

.vert-logo-amcham {
    position: absolute;
    left: 7%;
    top: 4%;
    max-width: 18%;
}

.amcham-popular-highlighter {
    border-radius: 100px;
    color: white;
    padding: 0 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
}

.ltr-badge-position {
    position: absolute;
    top: -1.5%;
    left: 34%;
}

.free-year-badge {
    width: 128%;
}

.amcham-logo-width {
    width: 15%;
}

// .light-orange {
// 	background: #F0CF76
// }

.new-banner-bg {
    background: url('./newPromotionBanner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 65vh;
}

.main-title {
    font-weight: 700;
    background: #0000ff;
    font-size: 3.25rem;
    color: #ffffff;
}

.green-bg {
    background: #00eb8c;
    color: #ffffff;
    font-size: 2rem;
    line-height: 3rem;
}

.line-height-2 {
    line-height: 2rem;
}

.w-80 {
    width: 88%;
    line-height: 2rem;
}

.amcham-desc {
    max-width: 95%;
}

.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

@media only screen and (min-width: 1441px) and (max-width: 1920px) {
    .amchamFreeMembership {
        position: absolute;
        background-color: #d33d42;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        width: 96%;
        left: 7px;
    }

    .promotion > i {
        width: 10%;
        text-align: end;
    }

    .promotion > p {
        width: 90%;
        text-align: start;
    }

    .free-year-badge {
        width: 129%;
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 32%;
    }
}

@media only screen and (min-width: 1250px) and (max-width: 1440px) {
    .promotion > i {
        width: 8%;
        text-align: end;
    }

    .promotion > p {
        width: 92%;
        text-align: start;
    }

    .free-year-badge {
        width: calc(100% + (calc(1439px - 1250px) / 1.92));
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 30%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1249px) {
    .promotion > i {
        width: 8%;
        text-align: end;
    }

    .promotion > p {
        width: 92%;
        text-align: start;
    }

    .free-year-badge {
        width: calc(100% + (calc(1248px - 1024px) / 2.5));
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 27%;
    }
}

@media only screen and (min-width: 920px) and (max-width: 1024px) {
    .promotion > p {
        margin-left: 25px;
    }

    .promotion > i {
        width: 5%;
        text-align: end;
    }

    .promotion > p {
        width: 95%;
    }

    .free-year-badge {
        width: calc(100% + (calc(1023px - 920px) / 1.25));
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 24%;
    }

    .vert-logo-amcham {
        position: absolute;
        left: 10%;
        top: 7%;
        max-width: 12%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 919px) {
    .promotion > p {
        margin-left: 25px;
    }

    .promotion > i {
        width: 5%;
        text-align: end;
    }

    .promotion > p {
        width: 95%;
    }

    .free-year-badge {
        width: calc(100% + (calc(918px - 768px) / 1.95));
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 15%;
    }

    .vert-logo-amcham {
        position: absolute;
        left: 10%;
        top: 7%;
        max-width: 12%;
    }

    .amcham-logo-width {
        width: 18%;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .promotion > p {
        margin-left: 18px;
    }

    .main-title {
        font-size: 2rem;
    }

    .green-bg {
        font-size: 1.65rem !important;
    }

    .min-height-520 {
        min-height: auto;
    }

    #promotionHome {
        margin-top: 20px;
        width: 312px;
    }

    .amchamFreeMembership {
        bottom: 45px;
    }

    .promotion > i {
        width: 25%;
        text-align: end;
    }

    .promotion > p {
        width: 75%;
    }

    .free-year-badge {
        width: 115%;
    }

    .vert-logo-amcham {
        position: absolute;
        left: 7%;
        top: 4%;
        max-width: 12%;
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 41%;
    }

    .amcham-logo-width {
        width: 18%;
    }
}

@media only screen and (min-width: 319px) and (max-width: 450px) {
    .promotion > p {
        margin-left: 20px;
    }

    .main-title {
        font-size: 2.28rem;
    }

    .green-bg {
        font-size: 1.5rem !important;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .amchamFreeMembership {
        position: absolute;
        background-color: #d33d42;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        width: 102%;
        bottom: 45px;
        left: -4px;
    }

    .promotion > i {
        width: 15%;
        text-align: end;
    }

    .promotion > p {
        width: 85%;
    }

    .free-year-badge {
        width: 115%;
    }

    .ltr-badge-position {
        position: absolute;
        top: -1.5%;
        left: 32%;
    }

    .vert-logo-amcham {
        position: absolute;
        left: 7%;
        top: 4%;
        max-width: 18%;
    }

    .amcham-logo-width {
        width: 50%;
    }
}
