@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.one-solution-all-sizes {
    padding-top: 76px;
    padding-bottom: 43px; // +20 for cards margin bottom

    @include media-breakpoint-up(md) {
        padding-top: 61px;
        padding-bottom: 42px; // +20 for cards margin bottom
    }
}

.header-wrapper {
    margin-bottom: 22px;
    @include media-breakpoint-up(md) {
        margin-bottom: 62px;
    }
}

.shopini-logistics {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
}

.title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    @include media-breakpoint-up(md) {
        font-size: 28px;
    }
}

.cards {
    min-width: 360px;
    width: 360px;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        width: calc(min(1300px, 90.278%));
    }
}

.card {
    width: 360px;
    padding: 20px 0 35px 0;
    margin-bottom: 20px;
    background-color: #ffffff;
    @include media-breakpoint-up(md) {
        width: calc(min(370px, 28.762%));
    }
}

.card-icon {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.card-title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    margin-bottom: 22px;
}

.card-contents {
    font-size: 18px;
    text-align: center;
}

.text-blue {
    color: #0000ff;
}

.outline-btn-overrides {
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 7px 25px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: 166px !important;
    border: 1px #0000ff solid !important;
    color: #0000ff;
    &:hover {
        background-color: #0000ff;
        border: 1px transparent solid !important;
    }
    &:active {
        background-color: #0000ff !important;
    }
}

.btn-overrides {
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 7px 25px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: 166px !important;
}
