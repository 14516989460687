.smart-tracking-image {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 57.759%;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../images/smart-tracking-image.svg');
    width: 100%;
}
