.font {
    text-align: left;

    html[dir='rtl'] & {
        font-family: 'Noto Sans Arabic' !important;
        text-align: right !important;
    }
}

.container-overrides {
    max-width: 820px;
}
