@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.partners {
    background-color: #ffffff;
    padding: 23px 0 39px 0;
    @include media-breakpoint-up(sm) {
        padding: 45px 0 61px 0;
    }
}

.title {
    margin-bottom: 17px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #0000ff;
    @include media-breakpoint-up(sm) {
        margin-bottom: 17px;
        font-size: 28px;
    }
}

.sub-title {
    margin-bottom: 26px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;

    @include media-breakpoint-up(sm) {
        font-size: 28px;
    }
}

.international-p {
    margin-bottom: 8px;
    @include media-breakpoint-up(sm) {
        margin-bottom: unset;
        width: 61.736%;
        max-width: 1320px;
    }
}

.domestic-p {
    @include media-breakpoint-up(sm) {
        width: 91.667%;
        max-width: 1320px;
    }
}
